import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { Session } from '../models/session'
import { limit, orderBy, where } from 'firebase/firestore'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import { openSessionRecordingsInFirebaseStorage } from '../interview-details-page/interview-details-page'
import { InterviewActionMenu } from '../interview-details-page/interview-action-menu'

export const UserInterviewsTab: React.FC = () => {
  const { userId } = useParams()

  const { data: interviewsQueryData, status: interviewsQueryStatus } = Session.useQuery(
    orderBy('createdAt', 'desc'),
    where('createdBy', '==', userId),
    limit(100)
  )

  if (interviewsQueryStatus === 'success' && !interviewsQueryData?.length)
    return <div className="text-center text-gray-500">User has not created any interviews</div>

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>ID</TableCell>
          <TableCell>Created at</TableCell>
          <TableCell>Status</TableCell>
          <TableCell>Minutes</TableCell>
          <TableCell>Transcript Length</TableCell>
          <TableCell>Recordings</TableCell>
          <TableCell className="w-[72px]"></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {interviewsQueryData?.map(session => (
          <TableRow key={session.id}>
            <TableCell>
              <Link to={`/interview/${session.id}`} target="_blank" className="text-blue-500 hover:underline">
                {session.id}
              </Link>
            </TableCell>
            <TableCell>{session.createdAt.toLocaleString()}</TableCell>
            <TableCell>{session.status}</TableCell>
            <TableCell>{session.totalUsedMinutes}</TableCell>
            <TableCell>{session.transcript?.length}</TableCell>
            <TableCell
              onClick={() => openSessionRecordingsInFirebaseStorage(session.recordings?.[0])}
            >
                    <span className="text-blue-500 hover:underline p-3 pl-0 cursor-pointer">
                      {session.recordings?.length ?? 0}
                    </span>
            </TableCell>
            <TableCell><InterviewActionMenu session={session}/></TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}