import { useHttpsCallable } from 'react-firebase-hooks/functions'
import { fbFunctions } from '../firebase'

export const useApplyReferralsEndpoint = () => useHttpsCallable<ApplyReferralsReq, ApplyReferralsRes>(fbFunctions, 'applyreferral')

type ApplyReferralsReq = { referredByEmail: string, referredUserIdOverride?: string }
type ApplyReferralsRes = { isReferralApplied: boolean, errorMessage?: string }

type RunAdminToolRequest =
  | { toolName: 'createToken', userId: string }
  | { toolName: 'cleanUpSessions', checkOrphanedFiles: boolean }
  | { toolName: 'regenerateNotes', sessionIds: string[] }
  | { toolName: 'getUserEvents', userId: string, limit?: number }

export const useRunAdminTool = () => useHttpsCallable<RunAdminToolRequest, any>(fbFunctions, 'runadmintool')