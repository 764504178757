import React from 'react'
import Check from '@mui/icons-material/Check'
import CircularProgress from '@mui/material/CircularProgress'
import { useHttpsCallable } from 'react-firebase-hooks/functions'
import { fbFunctions } from '../firebase'
import useCustomer from '../common/hooks/use-customer'
import { Customer } from '../models/customer'
import config from '../common/config'
import useUser from '../common/hooks/use-user'
import { TextField } from '@mui/material'
import Button from '../common/components/button'
import { useApplyReferralsEndpoint } from '../common/api'

const includedFeatures = [
  'Unlimited interviews',
  'Interview notes retained for 30 days',
  'Email support',
  'Desktop and mobile access via web app'
]

const PricingTable: React.FC = () => {
  const [customer] = useCustomer()
  const [user] = useUser()
  const [monthlyFee, perMinuteCents] = user?.price?.split('-').map(n => parseInt(n)) ?? [0, 0]
  const perMinuteFee = perMinuteCents / 100
  const isEarlyAdopter = user.createdAt?.toDate() < config.earlyAdopterDate
  const isTrialAvailable = !customer // user hasn't been a paying customer before
  const title = isEarlyAdopter ? `Thanks for being an early adopter ${user.firstName}!` : 'Simple pricing'
  const subtitle = isEarlyAdopter ? 'Here\'s our special early adopter deal as our thanks to you!' : `${isTrialAvailable ? 'Free 3-day trial. ' : ''}Charged monthly. Cancel any time.`
  const priceDescription = `$${monthlyFee} per month + $${perMinuteFee.toFixed(2)} per minute recorded.`
  const description = isTrialAvailable ? `Free 3-day trial giving you full access to NoteZap. Then it's just ${priceDescription}` : `Just ${priceDescription}`
  const [referralState, setReferralState] = React.useState({ isReferralEmailVisible: false, errorMessage: null as null | string })

  const [applyReferral, isApplyingReferral] = useApplyReferralsEndpoint()

  const handleReferralSubmit = async (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault()
    const formData = new FormData(evt.currentTarget)
    const referredByEmail = formData.get('email') as string
    if (!referredByEmail.trim().includes('@'))
      return
    const res = await applyReferral({ referredByEmail })
    if (res?.data.isReferralApplied) {
      setReferralState({ isReferralEmailVisible: false, errorMessage: null })
    } else {
      setReferralState({ isReferralEmailVisible: true, errorMessage: res?.data.errorMessage ?? null })
    }
  }

  if (user.price && !config.prices.includes(user.price))
    throw new Error(`Invalid price name "${user.price}"`)

  return (
    <div className="py-8 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-3xl sm:text-center">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{title}</h2>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            {subtitle}
          </p>
        </div>

        <div className="mx-auto mt-16 max-w-2xl rounded-3xl ring-1 ring-gray-200 sm:mt-20 lg:mx-0 lg:flex lg:max-w-none">
          <div className="p-8 sm:p-10 lg:flex-auto">
            <h3 className="text-2xl font-bold tracking-tight text-gray-900">NoteZap Subscription</h3>
            <p className="mt-6 text-base leading-7 text-gray-600">
              {description}
            </p>

            <div className="mt-10 flex items-center gap-x-4">
              <h4 className="flex-none text-sm font-semibold leading-6 text-indigo-600">What’s included</h4>
              <div className="h-px flex-auto bg-gray-100" />
            </div>
            <ul
              role="list"
              className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-gray-600 sm:grid-cols-2 sm:gap-6"
            >
              {includedFeatures.map((feature) => (
                <li key={feature} className="flex gap-x-3">
                  <Check className="h-6 w-5 flex-none text-indigo-600" aria-hidden="true" />
                  {feature}
                </li>
              ))}
            </ul>
          </div>
          <div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0 flex">
            <div className="rounded-2xl bg-gray-100 py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16 flex flex-grow">
              <div className="mx-auto max-w-xs px-8">
                <div className="mt-6 flex items-baseline justify-center gap-x-2">
                  <span className="text-5xl font-bold tracking-tight text-gray-900">${monthlyFee}</span>
                  <span className="text-sm font-semibold leading-6 tracking-wide text-gray-600">/month</span>
                </div>
                <div className="text-sm text-gray-600">plus ${perMinuteFee.toFixed(2)}/minute</div>

                <div className="mt-6">
                  <StartSubscriptionButton customer={customer} priceName={user.price} />
                </div>
              </div>
            </div>
          </div>
        </div>

        {!referralState.isReferralEmailVisible && !isApplyingReferral && !user.referredByUserId && (
          <div className="flex justify-center items-center mt-16 text-sm gap-1 text-gray-600">
            <span>Did someone refer you to NoteZap?</span>
            <Button variant="text" onClick={() => setReferralState(s => ({ ...s, isReferralEmailVisible: true }))}>Click here</Button>
          </div>
        )}

        {referralState.isReferralEmailVisible && (
          <div className="mt-16 flex justify-center">
            <form className="max-w-md grow flex items-start gap-2" action="#" method="POST" onSubmit={handleReferralSubmit}>
              <TextField label="Enter their email address" name="email" fullWidth size="small" autoComplete="off" error={!!referralState.errorMessage} helperText={referralState.errorMessage} autoFocus/>
              <Button variant="contained" type="submit" className="h-[40px]" isLoading={isApplyingReferral}>Submit</Button>
            </form>
          </div>
        )}

        {user.referredByUserId && (
          <div className="flex justify-center items-center mt-16 text-sm gap-1 text-gray-600">
            <span>Thanks for telling us about your referral! You now have access to our discounted referral pricing.</span>
          </div>
        )}
      </div>
    </div>
  )
}

export default PricingTable

export const StartSubscriptionButton: React.FC<{ customer: Customer | null, priceName: string }> = ({ customer, priceName }) => {
  const [createCheckoutSession, isCreatingCheckoutSession] = useHttpsCallable<{ priceName: string }, { sessionUrl: string }>(fbFunctions, 'createcheckoutsession')
  const handleClick = async () => {
    const result = await createCheckoutSession({ priceName })
    const sessionUrl = result?.data.sessionUrl
    if (sessionUrl) {
      window.location.href = sessionUrl!
    } else {
      console.error('Checkout session response did not include Session URL', result?.data)
    }
  }

  return (
    <Button
      variant="contained"
      onClick={handleClick}
      startIcon={isCreatingCheckoutSession && <CircularProgress className="!text-white" size={20}/>}
    >
      {!customer ? 'Start free 3-day trial' : 'Purchase subscription'}
    </Button>
  )
}