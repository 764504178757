/* eslint-disable react-hooks/rules-of-hooks */
import { QueryConstraint, updateDoc } from 'firebase/firestore'
import { UploadTask, deleteObject, listAll } from 'firebase/storage'
import * as fs from 'firebase/firestore'
import * as reactfire from 'reactfire'
import { auth, db, storage } from '../firebase'
import * as firebase from '../firebase'
import Request from './request'
import useDoc from '../common/hooks/use-doc'
import { useMemo } from 'react'
import * as recordingCache from '../common/recording-cache'

export type NoteSection = {
  name: string
  text: string
}

export class Session {
  public id: string
  public name: string
  public description: string
  public status: SessionStatus
  public percentComplete: number | null
  public type: string
  public recordings: string[]
  public totalSeconds: number
  public totalUsedMinutes: number
  public transcript: string | null
  public summary: string | null
  public noteSections: NoteSection[] | null

  public patientName: string | null
  public patientAge: number | null
  public patientGender: string | null
  public patientOtherInfo: string | null

  public model: 'claude' | 'gpt3.5' | null

  public createdBy: string
  public createdAt: Date

  constructor(session: Partial<Session> = {}) {
    this.id = session.id!
    this.name = session.name ?? 'New session'
    this.type = session.type ?? 'vet-visit'
    this.description = session.description ?? ''
    this.status = session.status ?? SessionStatus.New
    this.percentComplete = session.percentComplete ?? null
    this.recordings = session.recordings ?? []
    this.totalSeconds = session.totalSeconds ?? 0
    this.totalUsedMinutes = session.totalUsedMinutes ?? 0
    this.transcript = session.transcript ?? null
    this.summary = session.summary ?? null
    this.noteSections = session.noteSections ?? null

    this.patientName = session.patientName ?? null
    this.patientAge = session.patientAge ?? null
    this.patientGender = session.patientGender ?? null
    this.patientOtherInfo = session.patientOtherInfo ?? null

    this.model = session.model ?? null

    this.createdBy = session.createdBy ?? ''
    this.createdAt = session.createdAt ?? new Date()
  }

  private static getCollection() { return fs.collection(db, 'sessions') }

  static useQuery(...constraints: QueryConstraint[]) {
    const query = fs.query(Session.getCollection(), ...constraints)
    const result = reactfire.useFirestoreCollectionData(query)

    return { ...result, data: result.data?.map(fromFirebase) }
  }

  static useGet(sessionId?: string): [Session | null, boolean, Error | null] {
    const [sessionDoc, isLoading, error] = useDoc<Session>(`sessions/${sessionId}`)
    const session = useMemo(() => sessionDoc && new Session(fromFirebase(sessionDoc)), [sessionDoc])

    return [session, isLoading, error]
  }

  static async create(): Promise<Session> {
    const session = {
      name: 'New session',
      status: SessionStatus.New,
      createdBy: auth.currentUser!.uid,
      createdAt: new Date()
    }

    const res = await fs.addDoc(Session.getCollection(), session)
    return new Session({ ...session, id: res.id })
  }

  public async update(updates: Partial<Session>): Promise<void> {
    const ref = fs.doc(Session.getCollection(), this.id)
    await updateDoc(ref, updates)
  }

  public static async delete(id: string) {
    await recordingCache.deleteFile(id)
    const recordingsFolderRef = storage.ref(`users/${auth.currentUser!.uid}/sessionRecordings/${id}`)
    const recordings = await listAll(recordingsFolderRef)
    await Promise.all(recordings.items.map(deleteObject))
    return fs.deleteDoc(fs.doc(Session.getCollection(), id))
  }

  public uploadRecording(audio: Blob): UploadTask {
    const number = (this.recordings ?? []).length + 1
    const fileName = `recording-${number}.webm`
    const filePath = `users/${auth.currentUser!.uid}/sessionRecordings/${this.id}/${fileName}`

    return firebase.storage.uploadBytes(filePath, audio)
  }

  public async summarize(): Promise<void> {
    await Request.create({
      type: 'summarize-session',
      sessionId: this.id
    })
  }
}

const fromFirebase = (doc: any): Session => doc && new Session({
  ...doc,
  id: doc.id ?? doc.NO_ID_FIELD,
  createdAt: doc.createdAt?.toDate()
})

export enum SessionStatus {
  New = 'new',
  Uploading = 'uploading',
  Transcribing = 'transcribing',
  Transcribed = 'transcribed',
  TranscriptTooShort = 'transcript-too-short',
  Summarizing = 'summarizing',
  Summarized = 'summarized',
  Error = 'error',
  MissingSessionType = 'missing-session-type'
}
