import * as fs from 'firebase/firestore'
import { QueryConstraint } from 'firebase/firestore'
import * as reactfire from 'reactfire'
import { useFirestore } from 'reactfire'

export const useFirestoreDocData = <TDoc>(collection: string, id?: string): [TDoc | null, boolean] => {
  const path = collection && id ? [collection, id] : []
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const result = reactfire.useFirestoreDocData(fs.doc(useFirestore() as any, ...path))
  const isLoading = result.status === 'loading'
  const data = result.data && fromFirebase(result.data)
  return [data, isLoading]
}

export const useFirestoreCollectionData = <TDoc>(collection: string, ...constraints: QueryConstraint[]): TDoc[] | null => {
  const ref = fs.collection(useFirestore(), collection)
  const query = fs.query(ref, ...constraints)
  const result = reactfire.useFirestoreCollectionData(query)

  return result.data?.map(fromFirebase)
}

const fromFirebase = (doc: any) => doc && {
  ...doc,
  id: doc.id ?? doc.NO_ID_FIELD,
  createdAt: doc.createdAt?.toDate(),
  updatedAt: doc.updatedAt?.toDate()
}
