class LocalStorageValue<T = string> {
  constructor(public name: string, public options: { isJson?: boolean } = {} ) {}

  public get(): T | null {
    const storedValue = localStorage.getItem(this.name)
    return this.options.isJson ? JSON.parse(storedValue ?? 'null') : storedValue
  }

  public set(value: T) {
    const stringValue = this.options.isJson ? JSON.stringify(value) : value as string
    localStorage.setItem(this.name, stringValue)
  }

  public setOrRemove(shouldSet: boolean, value: T) {
    shouldSet ? this.set(value) : this.remove()
  }

  public remove() {
    localStorage.removeItem(this.name)
  }

  public isSet(): boolean {
    return this.get() !== null
  }
}

export const rememberMeLoginEmail = new LocalStorageValue('rememberMeLoginEmail')
export const lastUsedInterviewType = new LocalStorageValue('lastInterviewType')
export const autoLogoutAt = new LocalStorageValue('autoLogoutAt')
export const recentUserSearches = new LocalStorageValue<string[]>('recentUserSearches', { isJson: true })