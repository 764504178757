const isLocalDev = window.location.hostname === 'localhost' || !!window.location.port

const config = {
  isLocalDev,

  firebase: {
    apiKey: 'AIzaSyDrDWUuwNtVfO4AYlRtSp6mtPC7vDSs4kk',
    authDomain: 'notezap-9e624.firebaseapp.com',
    projectId: 'notezap-9e624',
    storageBucket: 'notezap-9e624.appspot.com',
    messagingSenderId: '344050199327',
    appId: '1:344050199327:web:4468cdba123d2080e2de2a',
    measurementId: 'G-ME2JKCL4PE'
  },

  recaptchaSiteKey: '6LeCwB0pAAAAACjXxgwMbvR75RAnAaDocCzH5JV3',

  functionsHost: isLocalDev ? 'http://localhost:5001/notezap-9e624/us-central1' : 'https://us-central1-notezap-9e624.cloudfunctions.net',

  earlyAdopterDate: new Date('2023-09-15'),
  earlyAdopterPriceName: '10-2',
  defaultPriceName: '25-2',
  prices: ['10-2', '20-2', '25-2']
}

export default config