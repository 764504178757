import React from 'react'
import Button from '../common/components/button'
import config from '../common/config'
import { TextareaAutosize } from '@mui/material'
import { useRunAdminTool } from '../common/api'


const DataCleanup: React.FC = () => {
  const [runAdminTool, isRunningAdminTool] = useRunAdminTool()
  const [sessionCleanUpResult, setSessionCleanupResult] = React.useState<any>()
  const [adminToolName, setAdminToolName] = React.useState<string>('')
  const [interviewIdsStr, setInterviewIdsStr] = React.useState<string>('')
  const interviewIds = interviewIdsStr.split(',').map(i => i.trim()).filter(i => !!i)

  const handleRunSessionCleanupClicked = async () => {
    if (!config.isLocalDev && !confirm('Are you sure you want to delete all expired sessions, recordings, and transcripts now?'))
      return

    setAdminToolName('cleanUpSessions')
    const res = await runAdminTool({ toolName: 'cleanUpSessions', checkOrphanedFiles: true })
    setSessionCleanupResult(res?.data)
  }

  const handleRegenerateNotesClick = async () => {
    setAdminToolName('regenerateNotes')
    const res = await runAdminTool({ toolName: 'regenerateNotes', sessionIds: interviewIds })
    setSessionCleanupResult(res?.data)
  }

  return (
    <div className="flex flex-col gap-8 pt-8">
      <div>
        <h2 className="text-xl font-bold">Old Interview Cleanup</h2>
        <p className="text-sm leading-6 text-gray-600 my-4">
          This immediately runs the session cleanup. It runs every night at midnight. Recordings and transcripts on sessions older than 7 days are deleted. Sessions older than 30 days are fully deleted.
        </p>
        <Button onClick={handleRunSessionCleanupClicked} isLoading={isRunningAdminTool && adminToolName === 'cleanupSessions'}>Run session cleanup now</Button>
        {sessionCleanUpResult && (
          <div className="flex flex-col gap-2 mt-2">
            <div>Deleted sessions: {sessionCleanUpResult.sessions}</div>
            <div>Deleted recordings: {sessionCleanUpResult.recordings}</div>
            <div>Deleted transcripts: {sessionCleanUpResult.transcripts}</div>
            <div>Deleted orphaned files: {sessionCleanUpResult.orphanedFiles}</div>
            <div>Duration: {sessionCleanUpResult.duration ?? '?'} seconds</div>
          </div>
        )}
      </div>

      <div>
        <h2 className="text-xl font-bold">Regenerate note sections</h2>
        <p className="text-sm leading-6 text-gray-600 my-4">
          Regenerates notes for the interviews with the given IDs.
        </p>
        <TextareaAutosize className="w-full border p-2" placeholder="Comma-separted interview IDs" minRows={3} onChange={evt => setInterviewIdsStr(evt.target.value)} />
        <Button onClick={handleRegenerateNotesClick} isLoading={isRunningAdminTool && adminToolName === 'regenerateNotes'} disabled={!interviewIds.length}>Regenerate {interviewIds.length || ''} notes</Button>
        {sessionCleanUpResult && (
          <div className="flex flex-col gap-2 mt-2">
            <div>Deleted sessions: {sessionCleanUpResult.sessions}</div>
            <div>Deleted recordings: {sessionCleanUpResult.recordings}</div>
            <div>Deleted transcripts: {sessionCleanUpResult.transcripts}</div>
            <div>Deleted orphaned files: {sessionCleanUpResult.orphanedFiles}</div>
            <div>Duration: {sessionCleanUpResult.duration ?? '?'} seconds</div>
          </div>
        )}
      </div>
    </div>
  )
}

export default DataCleanup