import React, { ReactNode } from 'react'

type Props = {
  name?: string,
  children: ReactNode,
  subheader?: ReactNode,
  actions?: ReactNode,
  alwaysPad?: boolean,
  center?: boolean
  nameIsPrivate?: boolean
}
const PageContent: React.FC<Props> = props => {
  const hideHeader = !props.name

  document.title = `NoteZap${props.name ? ` | ${props.name}` : ''}`

  return (
    <>
      {!hideHeader && (
        <header className="bg-white shadow">
          <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
            <div className="flex justify-between">
              <h1 className="text-3xl font-bold tracking-tight text-gray-900 border-2 border-transparent rounded" {...(props.nameIsPrivate ? { 'data-private': true } : {} )}>
                {props.name}
              </h1>
              {props.actions}
            </div>
            {props.subheader && (
              <div>{props.subheader}</div>
            )}
          </div>
        </header>
      )}
      <main className={`min-h-full ${props.center ? 'text-center' : ''}`}>
        <div className={`mx-auto max-w-7xl py-6 ${props.alwaysPad ? 'px-4' : 'sm:px-4'} lg:px-8`}>
          {props.children}
        </div>
      </main>
    </>
  )
}

export default PageContent