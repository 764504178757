import React, { useState } from 'react'
import { Alert, Button, TextField } from '@mui/material'
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { signInWithEmailAndPassword, signInWithCustomToken } from 'firebase/auth'
import { useAuth, useSigninCheck } from 'reactfire'
import PageContent from '../common/components/page-content'
import { authErrorMessages } from './auth-error-messages'
import { rememberMeLoginEmail } from '../common/local-storage'
import ForgotPasswordForm from './forgot-password-form'
import { trackLogin } from '../common/analytics'
import logger from '../common/logger'

const LoginPage: React.FC = () => {
  const [loginError, setLoginError] = useState<{ code?: string, message?: string } | null>(null)
  const navigate = useNavigate()
  const location = useLocation()
  const auth = useAuth()
  const [searchParams] = useSearchParams()
  const signInCheck = useSigninCheck()
  const [isRememberMeChecked, setIsRememberMeChecked] = useState(rememberMeLoginEmail.get() !== '')
  const isShowingForgotPassword = location.hash === '#forgotPassword'

  const handleSubmit = async (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault()
    const formData = new FormData(evt.currentTarget)
    const email = formData.get('email') as string
    const password = formData.get('password') as string
    const [tokenPrefix, token] = password.split('-')

    rememberMeLoginEmail.set(isRememberMeChecked ? email : '')

    try {
      setLoginError(null)
      if (tokenPrefix === 'nzadmin') {
        const { user } = await signInWithCustomToken(auth, token)
        trackLogin({ user })
      } else {
        const { user } = await signInWithEmailAndPassword(auth, email, password)
        trackLogin({ user })
      }
      const redirectTo = searchParams.get('redirectTo') ?? '/'
      navigate(redirectTo, { replace: true })
    } catch (err: any) {
      setLoginError(err)
      logger.error('Login error', err)
    }
  }

  if (signInCheck.status === 'loading') return null
  const isSignedIn = signInCheck.data?.signedIn
  if (isSignedIn)
    navigate(searchParams.get('redirectTo') ?? '/', { replace: true })

  return (
    <PageContent>
      <div className="flex min-h-full flex-1 flex-col justify-center md:py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <div className="mx-auto h-10 w-auto text-center text-4xl font-black bg-clip-text text-transparent"
            style={{ backgroundImage: 'linear-gradient(0deg, #5663c8, #00aeef)' }}>
            NoteZap
          </div>
          <h2 className="mt-2 md:mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            {isShowingForgotPassword ? 'Forgot your password?' : 'Sign in to your account'}
          </h2>

          {isShowingForgotPassword && (
            <p className="text-slate-600">No worries! We'll send you an email to reset your password.</p>
          )}
        </div>


        <div className="mt-3 md:mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
          <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
            {loginError && (
              <Alert severity="error" className="mb-8">
                {(loginError.code && authErrorMessages[loginError.code]) ?? loginError.message ?? 'Login failed'}
              </Alert>
            )}

            {isShowingForgotPassword ? <ForgotPasswordForm onError={setLoginError}/> : (
              <form className="space-y-6" action="#" method="POST" onSubmit={handleSubmit}>
                <TextField name="email" type="email" autoComplete="email" required fullWidth label="Email"
                  defaultValue={rememberMeLoginEmail.get() ?? ''}
                />

                <TextField name="password" type="password" autoComplete="current-password" required fullWidth
                  label="Password"/>

                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <input
                      id="remember-me"
                      name="remember-me"
                      type="checkbox"
                      defaultChecked={isRememberMeChecked}
                      className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                      onChange={evt => setIsRememberMeChecked(evt.target.checked)}
                    />

                    <label htmlFor="remember-me" className="ml-3 block text-sm leading-6 text-gray-900">
                      Remember my email
                    </label>
                  </div>

                  <div className="text-sm leading-6">
                    <Link to="#forgotPassword" className="font-semibold text-indigo-600 hover:text-indigo-500">
                      Forgot password?
                    </Link>
                  </div>
                </div>

                <Button type="submit" variant="contained" fullWidth>Sign in</Button>
              </form>
            )}
          </div>

          <p className="mt-10 text-center text-sm text-gray-500">
            Not a member?{' '}
            <Link to="/signup" className="font-semibold leading-6 text-indigo-600 hover:text-indigo-500 py-8">
              Start a 3 day free trial
            </Link>
          </p>
        </div>
      </div>
    </PageContent>
  )
}

export default LoginPage

