import React from 'react'
import { CheckIcon } from '@heroicons/react/24/solid'
import classNames from '../class-names'

const steps: string[] = [
  'Setup',
  'Record',
  'Review'
]

const Stepper: React.FC<{ currentStepIdx: number }> = props => {
  return (
    <ol role="list" className="divide-y divide-gray-300 sm:rounded-md border border-gray-300 md:flex md:divide-y-0 mb-10">
      {steps.map((step, stepIdx) => (
        <li key={step} className="relative md:flex md:flex-1">
          {props.currentStepIdx > stepIdx ? (
            <Step name={step} idx={stepIdx} done />
          ) : props.currentStepIdx === stepIdx ? (
              <Step name={step} idx={stepIdx} current />
          ) : (
            <Step name={step} idx={stepIdx} />
          )}

          {stepIdx !== steps.length - 1 ? (
            <>
              {/* Arrow separator for lg screens and up */}
              <div className="absolute right-0 top-0 hidden h-full w-5 md:block" aria-hidden="true">
                <svg
                  className="h-full w-full text-gray-300"
                  viewBox="0 0 22 80"
                  fill="none"
                  preserveAspectRatio="none"
                >
                  <path
                    d="M0 -2L20 40L0 82"
                    vectorEffect="non-scaling-stroke"
                    stroke="currentcolor"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            </>
          ) : null}
        </li>
      ))}
    </ol>
  )
}

const Step: React.FC<{ idx: number, name: string, done?: boolean, current?: boolean }> = props => {
  return (
    <div className="group flex w-full items-center">
      <span className="flex items-center px-2 py-2 sm:px-6 sm:py-4 text-xs sm:text-sm font-medium">
        <span className={classNames(
          props.done ? 'bg-indigo-600' : '',
          props.current ? 'border-2 border-indigo-600 text-indigo-600' : '',
          (!props.current && !props.done) ? 'border-2 border-gray-300 text-gray-500' : '',
          'flex h-5 w-5 sm:h-10 sm:w-10 flex-shrink-0 items-center justify-center rounded-full'
        )}>
          {props.done ? (
            <CheckIcon className="h-3 w-3 sm:h-6 sm:w-6 text-white" aria-hidden="true" />
          ) : (
            <span>{props.idx + 1}</span>
          )}
        </span>
        <span className={classNames(
          'ml-4 text-sm font-medium',
          props.done ? 'text-gray-900' : '',
          props.current ? 'text-indigo-600' : '',
          (!props.current && !props.done) ? 'text-gray-500' : '',
        )}>{props.name}</span>
      </span>
    </div>
  )
}
export default Stepper