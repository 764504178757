import { logEvent, setUserId as fbSetUserId, setUserProperties } from 'firebase/analytics'
import { analytics, fbFunctions } from '../firebase'
import config from './config'
import { httpsCallable } from 'firebase/functions'
import { omit } from 'lodash'

const BatchEventsAfterSeconds = 5

const events: AnalyticsEvent[] = []
let sendEventsTimer: NodeJS.Timeout | null = null

document.addEventListener('visibilitychange', sendEvents)

export const setUser = (user: AnalyticsUser | null) => {
  if (!user) return

  const isSuperAdmin = user.email?.toLowerCase().includes('@notezap.com') ?? false

  fbSetUserId(analytics, user.uid)
  setUserProperties(analytics, {
    isSuperAdmin,
    isLocalDev: config.isLocalDev
  })
}

const trackEvent = (name: string, params?: EventParams) => {
  console.log('[ANALYTICS EVENT]:', name, params ?? '')
  logEvent(analytics, name, params)

  events.push({
    name,
    ...omit(params, 'userId'),
    location: window.location.href,
    date: new Date().toISOString()
  })
  updateSendEventsTimer()
}

function updateSendEventsTimer() {
  if (!sendEventsTimer)
    sendEventsTimer = setTimeout(sendEvents, BatchEventsAfterSeconds * 1000)
}

async function sendEvents() {
  if (events.length === 0)
    return

  const callTrackEvents = httpsCallable(fbFunctions, 'trackevents')
  await callTrackEvents({ events })

  events.length = 0

  if (sendEventsTimer) {
    clearTimeout(sendEventsTimer!)
    sendEventsTimer = null
  }
}

export const trackError = (msg: string, err?: any) => {
  trackEvent('exception', { description: `${msg}${err ? `: ${err.message ?? err.stack}` : ''}` })
}

// Auth
export const trackLogin = (params: { user: AnalyticsUser }) => {
  setUser(params.user)
  trackEvent('login')
}
export const trackSignup = (params: { user: AnalyticsUser }) => {
  setUser(params.user)
  trackEvent('sign_up')
}

// Recording uploads
export const trackRecorderLoaded = () => trackEvent('recorder_loaded')
export const trackFileUpload = (p: { templateId: string, uploadingSeconds: number, bytes: number }) => trackEvent('file_upload', p)
export const trackRecordingStarted = (p: { sessionId: string }) => trackEvent('recording_started', p)
export const trackRecordingPaused = () => trackEvent('recording_paused')
export const trackRecordingResumed = () => trackEvent('recording_resumed')
export const trackRecordingDiscarded = () => trackEvent('recording_discarded')
export const trackRecordingUploadStarted = (p: { sessionId: string, templateId: string, recordingSeconds?: number, bytes: number }) => {
  trackEvent('recording_upload_started', p)
}
export const trackRecordingUploaded = (p: { sessionId: string, templateId: string, recordingSeconds?: number, uploadingSeconds: number, bytes: number }) => {
  trackEvent('recording_uploaded', p)
}
export const trackCachedRecordingUpload = (p: { bytes: number }) => trackEvent('cached_recording_upload', p)
export const trackCachedRecordingDelete = () => trackEvent('cached_recording_delete')

// Interview actions
export const trackRegenerateNotes = () => trackEvent('regenerate_notes')
export const trackCopyNote = () => trackEvent('copy_note')
export const trackCopyAllNotes = () => trackEvent('copy_all_note')
export const trackDeleteInterview = () => trackEvent('delete_interview')
export const trackEditInterview = (p: { templateId: string, changedTemplate: boolean, changedPatientName: boolean, changedPatientAge: boolean, changedPatientGender: boolean, changedPatientOtherInfo: boolean }) => {
  trackEvent('edit_interview', p)
}
export const trackCreateInterview = (p: { templateId: string, hasPatientName: boolean, hasPatientAge: boolean, hasPatientGender: boolean, hasPatientOtherInfo: boolean }) => {
  trackEvent('create_interview', p)
}

// Interview template actions
export const trackCreateInterviewTemplate = (p: { sectionCount: number }) => trackEvent('create_interview_template', p)
export const trackEditInterviewTemplate = (p: { sectionCount: number }) => trackEvent('edit_interview_template', p)

type AnalyticsUser = {
  uid: string
  email: string | null
}

type AnalyticsEvent = {
  name: string
  location: string
  date: string // ISO8601
} & EventParams

type EventParams = Record<string, string | number | boolean | undefined>
