import React from 'react'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import Table from '@mui/material/Table'
import { useFirestoreCollectionData } from '../common/hooks/firestore'
import { orderBy, limit, where, documentId, QueryConstraint } from 'firebase/firestore'
import { User } from '../models/user'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { recentUserSearches } from '../common/local-storage'
import { useBoolean } from 'usehooks-ts'
import EmptyState from '../common/components/empty-state'

const UsersPage: React.FC = () => {
  const [queryParams, setQueryParams] = useSearchParams()
  const navigate = useNavigate()
  const urlSearchEmail = decodeURIComponent(queryParams.get('search') ?? '')
  const [searchText, setSearchText] = React.useState<string>(urlSearchEmail)
  const users = useFirestoreCollectionData<User>('users', ...getFilter(searchText), limit(100))
  const isSearchSuggestionsOpen = useBoolean(false)

  const handleSearchChange = (evt: any, val?: any)=> {
    const pastedVal = evt.clipboardData?.getData('text')
    const value = pastedVal ?? val as string
    setSearchText(value)
    setQueryParams({ search: value ? encodeURIComponent(value) : '' }, { replace: true })
    if (value) {
      const updatedRecentSearches = Array.from(new Set([value, ...recentUserSearches.get() ?? []]).values()).slice(0, 10)
      recentUserSearches.set(updatedRecentSearches)
    }
    if (pastedVal)
      setTimeout(() => isSearchSuggestionsOpen.setFalse(), 10)
  }

  return (
    <div className="mt-4 flex flex-col gap-4">
      <div className="flex justify-between gap-2">
        <div className="grow max-w-sm">
          <Autocomplete
            size="small"
            freeSolo
            value={searchText}
            open={isSearchSuggestionsOpen.value}
            onOpen={isSearchSuggestionsOpen.setTrue}
            onClose={isSearchSuggestionsOpen.setFalse}
            options={recentUserSearches.get() ?? []}
            onChange={handleSearchChange}
            onPaste={handleSearchChange}
            renderInput={(params) => (
              <TextField{...params} placeholder="Search email or ID" />
            )}
          />
        </div>
      </div>

      {!users?.length && (
        <EmptyState title="No users found" subtitle={`There are no users ${searchText?.includes('@') ? 'with email' : 'with ID'} "${searchText}"`} />
      )}

      {!!users?.length && (
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Email</TableCell>
              <TableCell>Created</TableCell>
              <TableCell>Price</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {users?.map(user => (
              <TableRow hover key={user.id} className="cursor-pointer" onClick={() => navigate(`/user/${user.id}`)}>
                <TableCell>{user.email}</TableCell>
                <TableCell>{user.createdAt.toLocaleString()}</TableCell>
                <TableCell>{user.price}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </div>
  )
}

const getFilter = (searchText: string): QueryConstraint[] => {
  if (!searchText)
    return []

  if (searchText.includes('@'))
    return [where('email', '==', searchText.toLowerCase()), orderBy('createdAt', 'desc')]

  return [where(documentId(), '==', searchText)]
}

export default UsersPage

