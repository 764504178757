import React, { ReactNode } from 'react'

const FormSection: React.FC<{ title: string, subtitle?: string, children: ReactNode, actions?: ReactNode | ReactNode[] }> = props => (
  <div className="border-b border-gray-900/10 pb-4 md:pb-12 mb-8">
    <div className="flex justify-between">
      <h2 className="text-base font-semibold leading-7 text-gray-900">{props.title}</h2>
      <div>
        {props.actions}
      </div>
    </div>
    {props.subtitle && (<p className="text-sm leading-6 text-gray-600">{props.subtitle}</p>)}
    <div className="mt-4">
      {props.children}
    </div>
  </div>
)

export default FormSection