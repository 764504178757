import TextField from '@mui/material/TextField'
import React, { useState } from 'react'
import medicationNames from '../common/medication-names.json'

const Keywords: React.FC = () => {
  const [keywords, setKeywords] = useState(medicationNames.sort())
  const [search, setSearch] = useState('')

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if(event.key === 'Enter' && search.length > 3)
      setKeywords([...keywords, search].sort())
  }

  return (
    <div>
      <div className="my-4 relative">
        <p className="text-sm leading-6 text-gray-600 my-4">
          A list of all the default keywords available to users when they're editing their transcripts.
        </p>

        <TextField fullWidth placeholder="Search" onChange={e => setSearch(e.target.value)} onKeyDown={handleKeyDown}/>
      </div>

      <div>
        {keywords.filter(k => k.includes(search)).map(k => (
          <div key={k}>{k}</div>
        ))}
      </div>
    </div>
  )
}

export default Keywords