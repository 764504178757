import React from 'react'
import { Session } from '../models/session'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { Checkbox, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'
import { where, orderBy } from 'firebase/firestore'
import { useUser } from 'reactfire'
import PageContent from '../common/components/page-content'
import { DateTime, DateTimeFormatOptions } from 'luxon'
import Button from '../common/components/button'
import Mic from '@mui/icons-material/Mic'
import Add from '@mui/icons-material/Add'
import Delete from '@mui/icons-material/Delete'

const InterviewsListPage: React.FC = () => {
  const user = useUser()
  const { data } = Session.useQuery(where('createdBy', '==', user.data!.uid), orderBy('createdAt', 'desc'))
  const navigate = useNavigate()
  const dateFormat: DateTimeFormatOptions = { ...DateTime.DATE_MED, ...DateTime.TIME_SIMPLE, weekday: 'short' }
  const [searchParams] = useSearchParams()
  const [selectedIds, setSelectedIds] = React.useState<string[]>([])
  const [isDeleting, setIsDeleting] = React.useState<boolean>(false)

  if (!data) return null
  const sessions = data as Session[]

  const handleSelectAllChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target
    setSelectedIds(checked ? sessions.map(s => s.id) : [])
  }

  const handleSelectRowChange = (checked: boolean, id: string) => {
    if (checked) {
      setSelectedIds([...selectedIds, id])
    } else {
      setSelectedIds(selectedIds.filter(i => i !== id))
    }
  }

  const handleDeleteSelectedClick = async () => {
    if (!confirm(`Are you sure you want to delete ${selectedIds.length} interviews? This cannot be undone.`))
      return

    setIsDeleting(true)
    for (const id of selectedIds) {
      await Session.delete(id)
      console.log('deleted', id)
      setSelectedIds(s => s.filter(i => i !== id))
    }
    setIsDeleting(false)
  }

  if (!sessions.length) {
    return (
      <PageContent name="Interviews">
        <div className="text-center">
          {searchParams.has('payment-success') && (
            <div className=" mb-10">
              <div className="text-2xl font-bold">Thanks for starting your trial!</div>
            </div>
          )}
          <Mic fontSize="large" />
          <h3 className="mt-2 text-sm font-semibold text-gray-900">No interviews</h3>
          <p className="mt-1 text-sm text-gray-500">Get started by creating a new interview</p>
          <div className="mt-6">
            <Button startIcon={<Add />} href="/interview/new">
              New Interview
            </Button>
          </div>
        </div>
      </PageContent>
    )
  }

  return (
    <PageContent name="Interviews">
      <div>
        <div className="flex justify-end mb-4 h-10 px-4 md:px-0">
          <div className={`transition-opacity ${selectedIds.length > 0 ? 'opacity-100' : 'opacity-0 transition-none'}`}>
            <Button color="error" isLoading={isDeleting} startIcon={<Delete />} onClick={handleDeleteSelectedClick}>
              Delete {selectedIds.length} interview{selectedIds.length === 1 ? '' : 's'}
            </Button>
          </div>
        </div>

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className="w-12"><Checkbox onChange={handleSelectAllChange} checked={selectedIds.length === sessions.length} /></TableCell>
                <TableCell>Date</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Patient</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {sessions.map(s => (
                <TableRow hover style={{ cursor: 'pointer' }} onClick={() => navigate(`/interview/${s.id}`)} key={s.id}>
                  <TableCell>
                    <Checkbox onClick={e => e.stopPropagation()} onChange={e => handleSelectRowChange(e.target.checked, s.id)} checked={selectedIds.includes(s.id)} />
                  </TableCell>
                  <TableCell>
                    <Link to={`/interview/${s.id}`} onClick={evt => evt.stopPropagation()}>
                      {s.createdAt && DateTime.fromJSDate(s.createdAt).toLocaleString(dateFormat)}
                    </Link>
                  </TableCell>
                  <TableCell data-private>{s.name === 'New session' ? '' : s.name}</TableCell>
                  <TableCell data-private>{s.patientName}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        <div className="m-4 sm:mx-0 flex items-baseline gap-1">
          <span className="inline-flex items-center rounded-md bg-red-100 px-2 py-1 text-xs font-medium text-red-700">NOTE</span>
          <span className="text-sm text-gray-600">Interviews are auto-deleted after 30 days.</span>
        </div>
      </div>
    </PageContent>
  )
}

export default InterviewsListPage
