import React, { useEffect } from 'react'
import PageContent from '../common/components/page-content'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import useUser from '../common/hooks/use-user'
import Tabs from '../common/components/tabs'

const tabs = [
  { name: 'Users', href: '/admin/users' },
  { name: 'Data cleanup', href: '/admin/data-cleanup' },
  { name: 'Keywords', href: '/admin/keywords' }
]

const AdminPage: React.FC = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const [user] = useUser()

  useEffect(() => {
    if (user && !user.isAdmin) {
      navigate('/')
    } else if (user && user.isAdmin && location.pathname === '/admin')
      navigate(tabs[0].href)
  }, [user, location])

  return (
    <PageContent name="Admin" alwaysPad>
      <Tabs tabs={tabs} />

      <Outlet />
    </PageContent>
  )
}

export default AdminPage