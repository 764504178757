import React, { ReactNode } from 'react'
import PageContent from '../common/components/page-content'
import PricingTable from './pricing-table'
import useCustomer from '../common/hooks/use-customer'
import { Customer } from '../models/customer'
import { DateTime } from 'luxon'
import useUser from '../common/hooks/use-user'
import Button from '@mui/material/Button'
import { fbFunctions } from '../firebase'
import { useHttpsCallable } from 'react-firebase-hooks/functions'
import CircularProgress from '@mui/material/CircularProgress'
import Alert from '../common/components/alert'

const AccountPage: React.FC = () => {
  const [customer, isLoadingCustomer] = useCustomer()
  const [user] = useUser()

  return (
    <PageContent name="My account">
      {customer?.subscription || !user.price
        ? <ActiveAccount customer={customer}/>
        : <PricingTable />
      }
    </PageContent>
  )
}

export default AccountPage

const ActiveAccount: React.FC<{ customer: Customer | null }> = ({ customer }) => {
  const [user] = useUser()
  const subscription = customer?.subscription
  const subscriptionExpires = subscription && DateTime.fromJSDate(subscription.currentPeriodEnd.toDate())
  const usageMinutes = (customer?.currentPeriodUsageMinutes ?? 0)
  const [monthlyFee, perMinuteFee] = user?.price?.split('-').map(n => parseInt(n)) ?? [0, 0]
  const currentBillEstimate = usageMinutes * (perMinuteFee / 100) + monthlyFee
  const [createPortalSession, isCreatingPortalSession] = useHttpsCallable<never, { sessionUrl: string }>(fbFunctions, 'createstripeportalsession')

  const handleManageSubscriptionClick = async () => {
    const result = await createPortalSession()
    if (result)
      window.location.href = result.data.sessionUrl
  }

  return (
    <div>
      {subscription?.status === 'past_due' && (
        <Alert title="Account past due" message="Your account payment is past due. Click 'Manage subscription' below to make sure your billing information is correct. Double check your payment method and billing address info. If everything looks correct, you can message us at support@notezap.com "/>
      )}
      <dl className="divide-y divide-gray-200">
        <DetailsRow name="Name">{user.firstName} {user.lastName}</DetailsRow>
        <DetailsRow name="Email">{user.email}</DetailsRow>
        <DetailsRow name="Usage">
          You've used <span className="font-bold">{usageMinutes}</span> minutes {subscription && (subscription?.status === 'trialing' ? 'during the free trial' : 'this billing period')}
        </DetailsRow>

        {!!user.referralCount && (
          <DetailsRow name="Referrals">
            <div>You've referred <span className="font-bold">{user.referralCount}</span> {user.referralCount === 1 ? 'person' : 'people'} and earned <span className="font-bold">${user.referralCreditTotal ?? 0}</span> total referral credit.</div>
            <div className="text-gray-500">Referral credit is earned when a referred user makes their first month's payment. Your credit balance will be applied to your invoices.</div>
          </DetailsRow>
        )}

        {subscription && (
          <DetailsRow
            name="Billing cycle"
            action={<Button onClick={handleManageSubscriptionClick} startIcon={isCreatingPortalSession && <CircularProgress size={20}/>}>Manage subscription</Button>}
          >
            {subscription.status === 'trialing' ? (
              <div>
                Your free trial will end on <span className="font-bold">{subscriptionExpires!.toLocaleString(DateTime.DATE_FULL)} </span>
                then you'll be billed monthly at: <span className="font-bold">${monthlyFee}/month + ${(perMinuteFee / 100).toFixed(2)}/minute.</span>
              </div>
            ) : (
              <div>
                You'll be billed<span className="font-bold"> ${currentBillEstimate?.toFixed(2)} </span>+ tax
                on <span className="font-bold">{subscriptionExpires!.toLocaleString(DateTime.DATE_FULL)}</span>
                <div className="text-gray-500">${monthlyFee}/month + ${(perMinuteFee / 100).toFixed(2)}/minute x {usageMinutes} minutes = ${currentBillEstimate?.toFixed(2)}</div>
                <div className="text-gray-500">(this will increase with usage)</div>
              </div>
            )}
          </DetailsRow>
        )}
      </dl>
    </div>
  )
}

const DetailsRow: React.FC<{ name: string, children: ReactNode, action?: ReactNode }> = props => (
  <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
    <dt className="text-sm font-medium leading-6 text-gray-900">{props.name}</dt>

    <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-0">
      {props.children}
    </dd>

    {props.action && (
      <dd>
        <div className="-ml-2 sm:ml-0 sm:-mt-2">
          {props.action}
        </div>
      </dd>
    )}
  </div>
)