import React, { ReactNode, useMemo, useState } from 'react'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import ContentPaste from '@mui/icons-material/ContentPaste'
import Refresh from '@mui/icons-material/Refresh'
import { useParams } from 'react-router-dom'
import { Session, SessionStatus } from '../models/session'
import PageContent from '../common/components/page-content'
import Stepper from '../common/components/stepper'
import Recorder from './recorder'
import Request from '../models/request'
import { db } from '../firebase'
import classNames from '../common/class-names'
import { NotFoundPage } from '../common/components/error-page'
import { TranscriptEditor } from './transcript-editor'
import { doc, updateDoc } from 'firebase/firestore'
import Alert from '../common/components/alert'
import EmptyState from '../common/components/empty-state'
import { trackCopyAllNotes, trackCopyNote } from '../common/analytics'
import { useAuth } from 'reactfire'
import config from '../common/config'
import { InterviewActionMenu } from './interview-action-menu'

const SessionDetailsPage: React.FC = () => {
  const { sessionId } = useParams()
  const [session, isLoadingSession] = Session.useGet(sessionId)

  if (isLoadingSession)
    return null
  if (!session)
    return <NotFoundPage title="Interview not found" message="This interview may have been deleted or you may not have access to it." />

  const allSectionsText = session.noteSections?.map((ns: any) => `${ns.name}\n\n${ns.text}`).join('\n\n') ?? ''

  const handleCopyAllClick = () => {
    trackCopyAllNotes()
    return navigator.clipboard.writeText(allSectionsText)
  }

  return (
    <PageContent
      name={!session.name || session.name === 'New session' ? 'Interview details' : session.name}
      nameIsPrivate
      subheader={
        <div>
          {session.createdAt.toLocaleString()}
          {!!session.totalUsedMinutes && (
            <span> - {session.totalUsedMinutes} minute{session.totalUsedMinutes === 1 ? '' : 's'}</span>
          )}
          <span data-private>{session.patientName && ` - ${session.patientName}`}</span>
        </div>
      }
      actions={(
        <div className="flex items-start space-x-2">
          <InterviewActionMenu session={session} />

          {session.noteSections && (
            <Button
              startIcon={<ContentPaste/>}
              variant="contained"
              className="whitespace-nowrap"
              onClick={handleCopyAllClick}
            >
              Copy all
            </Button>
          )}
        </div>
      )}
    >
      <SessionDetailsPageContent session={session}/>
    </PageContent>
  )
}

export const openSessionRecordingsInFirebaseStorage = (recordingPath: string) => {
  if (!recordingPath)
    return
  const folder = recordingPath.split('/').slice(0, -1).join('/')
  const url = config.isLocalDev
    ? `http://localhost:4000/storage/notezap-9e624.appspot.com/${folder}`
    : `https://console.firebase.google.com/u/0/project/notezap-9e624/storage/notezap-9e624.appspot.com/files/${encodeURIComponent(folder)}`

  window.open(url, '_blank')
}

type SectionProps = {
  name: string
  text: string | null
  explanation?: string
  classes?: { text?: string }
  onEdit?: (edited: string) => void
  onRegenerate?: () => Promise<void>
}
const Section: React.FC<SectionProps> = props => {
  const [hasEdits, setHasEdits] = useState(false)

  const handleCopyClick = () => {
    trackCopyNote()
    if (props.text === null || props.text === undefined) return
      navigator.clipboard.writeText(props.text)
  }

  const handleChange = async (updatedTranscript: string) => {
    setHasEdits(true)
    props.onEdit?.(updatedTranscript)
  }

  const handleRegenerate = async () => {
    await props.onRegenerate?.()
    setHasEdits(false)
  }

  if (props.text === null || props.text === undefined)
    return null

  return (
    <div className="relative rounded-md mt-6 bg-white px-6 py-4 shadow">
      <h2 className="text-2xl font-bold tracking-tight pr-6 text-gray-900">{props.name}</h2>
      <div className={classNames('mt-6 whitespace-pre-wrap', props.classes?.text)} data-private={!!props.text}>
        {props.text
          ? props.onEdit ? <TranscriptEditor transcript={props.text} onChange={handleChange}/> : props.text
          : '(empty)'
        }
      </div>

      {/*Actions*/}
      <div className="select-none absolute top-0 right-0 m-3">
        {hasEdits && props.onRegenerate && <Button startIcon={<Refresh />} onClick={handleRegenerate}>Regenerate notes</Button>}
        {props.text && <IconButton onClick={handleCopyClick} aria-label="Copy note section text"><ContentPaste/></IconButton>}
      </div>
    </div>
  )
}

const Divider: React.FC<{ children: ReactNode }> = props => (
  <div className="relative my-16 select-none">
    <div className="absolute inset-0 flex items-center" aria-hidden="true">
      <div className="w-full border-t border-gray-300"/>
    </div>
    <div className="relative flex justify-center">
      <span className="bg-gray-50 px-2 text-sm text-gray-500">{props.children}</span>
    </div>
  </div>
)

const segmenter = new (Intl as any).Segmenter('en', { granularity: 'sentence' })

const SessionDetailsPageContent: React.FC<{ session: Session }> = props => {
  const { session } = props
  const auth = useAuth()

  const transcriptSentences = useMemo(() => session.transcript?.includes('\n')
    ? session.transcript
    : Array.from(segmenter.segment(session.transcript), (s: any) => s.segment).join('\n'), [session.transcript])

  const handleTranscriptEdited = async (edited: string) => {
    const ref = doc(db, `sessions/${session.id}`)
    await updateDoc(ref, { transcript: edited })
  }

  const handleRegenerate = async () => {
    await Request.create({ type: 'summarize-session', sessionId: session.id })
  }

  if (session.status === SessionStatus.New || session.status === SessionStatus.Uploading) {
    return (
      <div>
        <Stepper currentStepIdx={1} />

        <div className="flex flex-col items-center justify-center">
          <Recorder session={session} />
        </div>
      </div>
    )
  }
  if (session.status === SessionStatus.Transcribing)
    return <EmptyState progress title="Transcribing" subtitle="Your interview is being transcribed. This should take less than a minute." />

  if (session.status === SessionStatus.Error) {
    return (
      <EmptyState
        title="Whoops, looks like something went wrong generating this note."
        subtitle={(
          <div>The team has been notified but you can message <span className="font-bold">support@notezap.com</span> if you need help.</div>
        )}
      />
    )
  }

  return (
    <>
      {session.status === SessionStatus.Summarizing && (
        <EmptyState progress title="Generating notes" subtitle="Your notes are being generated. This should take less than a minute." />
      )}

      {session.status === SessionStatus.TranscriptTooShort && (
        <Alert title="Transcript too short" message="This transcript was too short to generate notes." />
      )}

      {session.status === SessionStatus.MissingSessionType && (
        <Alert title="Missing template" message="The template for this interview has been deleted. Notes cannot be regenerated until a new template is selected." />
      )}

      {session.noteSections && (
        <div>
          {session.noteSections.map((ns: any, idx) => (
            <Section name={ns.name} text={ns.text} key={idx} classes={{ text: session.createdBy !== auth.currentUser?.uid ? 'blur-sm' : '' }} />
          ))}
        </div>
      )}

      {session.noteSections && session.transcript && (
        <Divider>Transcript and other info</Divider>
      )}

      {session.transcript && (
        <div>
          <Section
            name="Transcript"
            text={transcriptSentences}
            classes={{ text: `leading-loose ${session.createdBy !== auth.currentUser?.uid ? 'blur-sm' : ''}` }}
            onEdit={handleTranscriptEdited}
            onRegenerate={handleRegenerate}
          />

          <div className="m-4 sm:mx-0 flex items-baseline gap-1">
            <span className="inline-flex items-center rounded-md bg-red-100 px-2 py-1 text-xs font-medium text-red-700">NOTE</span>
            <span className="text-sm text-gray-600">Transcripts are auto-deleted 7 days after the interview was created.</span>
          </div>
        </div>
      )}
    </>
  )
}

export default SessionDetailsPage
