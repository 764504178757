import React, { ReactNode, useEffect } from 'react'
import PageContent from './page-content'
import { Link, useRouteError } from 'react-router-dom'
import logger from '../logger'

const ErrorPage: React.FC<{ code?: string, title?: string, children?: ReactNode }> = props => {
  const error = useRouteError()
  useEffect(() => {
    logger.error('Showing error page', error)
  }, [error])

  return (
    <PageContent>
      <div className="text-center pt-40">
        <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-2xl">{props.title ?? 'Oops, something went wrong'}</h1>
        <div className="mt-6 text-base leading-7 text-gray-600">
          <div>Try reloading the page.</div>
          <div>If the problem continues please contact us at <span className="font-bold">support@notezap.com</span></div>
        </div>

        <div className="mt-10 flex items-center justify-center gap-x-6">
          <Link
            to="/"
            className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Go back home
          </Link>
        </div>
      </div>
    </PageContent>
  )
}

export const NotFoundPage: React.FC<{ title?: string, message?: string }> = props => (
  <PageContent>
    <div className="text-center pt-40">
      <p className="text-base font-semibold text-indigo-600">404</p>
      <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">{props.title ?? 'Page not found'}</h1>
      <div className="mt-6 text-base leading-7 text-gray-600">{props.message ?? 'Sorry, we couldn\'t find the page you’re looking for.'}</div>

      <div className="mt-10 flex items-center justify-center gap-x-6">
        <Link
          to="/"
          className="rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          Go back home
        </Link>
      </div>
    </div>
  </PageContent>
)

export default ErrorPage