import { initializeApp } from 'firebase/app'
import { initializeAppCheck, ReCaptchaEnterpriseProvider } from 'firebase/app-check'
import { getPerformance } from 'firebase/performance'
import { getAnalytics } from 'firebase/analytics'
import { connectFunctionsEmulator, getFunctions } from 'firebase/functions'
import { connectStorageEmulator, getStorage, ref, uploadBytesResumable } from 'firebase/storage'
import { connectFirestoreEmulator, initializeFirestore } from 'firebase/firestore'
import { browserLocalPersistence, connectAuthEmulator, initializeAuth } from 'firebase/auth'
import React, { ReactElement } from 'react'
import { AuthProvider, FirebaseAppProvider, FirestoreProvider } from 'reactfire'
import { setUser } from './common/analytics'
import config from './common/config'

export const app = initializeApp(config.firebase)

export const auth = initializeAuth(app, { persistence: browserLocalPersistence })
export const db = initializeFirestore(app, { experimentalAutoDetectLongPolling: true })
export const fbFunctions = getFunctions(app)
const fbStorage = getStorage()
export const perf = getPerformance(app)
export const analytics = getAnalytics(app)

if (config.isLocalDev) {
  const { hostname } = window.location
  connectAuthEmulator(auth, `http://${hostname}:9099`)
  connectFunctionsEmulator(fbFunctions, hostname, 5001)
  connectFirestoreEmulator(db, hostname, 8080)
  connectStorageEmulator(fbStorage, hostname, 9199)
} else {
  initializeAppCheck(app, {
    provider: new ReCaptchaEnterpriseProvider(config.recaptchaSiteKey),
    isTokenAutoRefreshEnabled: true
  })
}

auth.onAuthStateChanged(setUser)

const storageRef = (path: string) => ref(fbStorage, path)
const storageUploadBytes = (path: string, bytes: Blob) => uploadBytesResumable(storageRef(path), bytes)

export const storage = {
  ref: storageRef,
  uploadBytes: storageUploadBytes
}

export const FirebaseProvider: React.FC<{ children: ReactElement }> = props => (
  <FirebaseAppProvider firebaseApp={app}>
    <AuthProvider sdk={auth}>
      <FirestoreProvider sdk={db}>
        {props.children}
      </FirestoreProvider>
    </AuthProvider>
  </FirebaseAppProvider>
)
