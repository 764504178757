import { useEffect, useState } from 'react'

const CheckIntervalMs = 2000

export default function useIsAudioDetected(stream: MediaStream | null): [boolean, boolean] {
  const [isAudioDetected, setIsAudioDetected] = useState(false)
  const [isReady, setIsReady] = useState(false)

  useEffect(() => {
    if (!stream)
      return

    const audioContext = new AudioContext()
    const analyser = audioContext.createAnalyser()
    const source = audioContext.createMediaStreamSource(stream)
    source.connect(analyser)
    analyser.fftSize = 32
    const bufferLength = analyser.frequencyBinCount
    const dataArray = new Uint8Array(bufferLength)

    const detectAudio = () => {
      setIsReady(true)
      analyser.getByteFrequencyData(dataArray)
      const average = dataArray.reduce((a, b) => a + b) / bufferLength

      if (average > 5)
        setIsAudioDetected(true)
    }
    const interval = setInterval(detectAudio, CheckIntervalMs)

    return () => {
      clearInterval(interval)
      source.disconnect()
      analyser.disconnect()
    }
  }, [stream])
  
  return [isAudioDetected, isReady]
}