import { useLocation } from 'react-router-dom'
import { useEffect } from 'react'
import { DateTime } from 'luxon'
import { useAuth } from 'reactfire'
import { autoLogoutAt } from '../local-storage'
import useUser from './use-user'
import logger from '../logger'

const AUTO_LOGOUT_AFTER_HOURS = 3

let timeout: NodeJS.Timeout

export default function useAutoLogout() {
  const location = useLocation()
  const auth = useAuth()
  const [user] = useUser()

  useEffect(() => {
    clearTimeout(timeout)

    const isTimedOut = autoLogoutAt.isSet() && DateTime.now() > DateTime.fromISO(autoLogoutAt.get()!)
    if (isTimedOut) {
      signOut()
    } else {
      resetTimeout()
    }

    return () => clearTimeout(timeout)
  }, [location.pathname, user])

  const resetTimeout = () => {
    const hours = user.autoLogoutHours ?? AUTO_LOGOUT_AFTER_HOURS
    const newAutoLogoutAt = DateTime.now().plus({ hours })
    autoLogoutAt.set(newAutoLogoutAt.toISO()!)
    const millisUntilAutoLogout = newAutoLogoutAt.diffNow('milliseconds').milliseconds

    clearTimeout(timeout)
    if (hours < 100)
      timeout = setTimeout(signOut, millisUntilAutoLogout)
  }

  const signOut = () => {
    logger.info(`Automatically logging user out after ${AUTO_LOGOUT_AFTER_HOURS} hours of inactivity`)
    autoLogoutAt.remove()
    auth.signOut()
      .then(() => {
        window.location.reload()
      })
  }
}