import * as analytics from './analytics'

const logger = {
  info: (...args: any[]) => {
    console.log(...args)
  },
  error: (msg: string, err?: any) => {
    analytics.trackError(msg, err)
    console.error(msg, err)
  }
}

export default logger