import React, { ReactElement, useEffect } from 'react'
import {
  Route, createBrowserRouter, createRoutesFromElements, RouterProvider, useLocation
} from 'react-router-dom'
import InterviewsListPage from './interviews-list-page'
import InterviewDetailsPage from './interview-details-page'
import LoginPage from './login-page'
import PageLayout from './page-layout'
import { useSigninCheck } from 'reactfire'
import ErrorPage, { NotFoundPage } from './common/components/error-page'
import NewInterviewPage from './new-interview-page'
import AdminPage from './admin-page'
import SignUpPage from './signup-page'
import EditInterviewPage from './edit-interview-page'
import AccountPage from './account-page'
import DataCleanup from './admin-page/data-cleanup'
import Keywords from './admin-page/keywords'
import UsersPage from './admin-page/users-page'
import UserDetailsPage, { UserDetailsTab } from './admin-page/user-details-page'
import { UserEventsTab } from './admin-page/user-events-tab'
import { UserInterviewsTab } from './admin-page/user-interviews-tab'

const Routes: React.FC = () => (
  <RouterProvider router={createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" element={<PageLayout />} errorElement={<ErrorPage />}>
        <Route path="login" element={<LoginPage />} errorElement={<ErrorPage />} />
        <Route path="signup" element={<SignUpPage />} errorElement={<ErrorPage />} />

        <Route path="account" element={<RequireAuth><AccountPage /></RequireAuth>} errorElement={<ErrorPage />} />

        <Route index element={<RequireAuth><InterviewsListPage /></RequireAuth>} errorElement={<ErrorPage />}/>
        <Route path="interview/new" element={<RequireAuth><NewInterviewPage /></RequireAuth>} errorElement={<ErrorPage />} />
        <Route path="interview/:sessionId" element={<RequireAuth><InterviewDetailsPage /></RequireAuth>} errorElement={<ErrorPage />} />
        <Route path="interview/:sessionId/edit" element={<RequireAuth><EditInterviewPage /></RequireAuth>} errorElement={<ErrorPage />} />

        <Route path="admin" element={<RequireAuth><AdminPage /></RequireAuth>} errorElement={<ErrorPage />}>
          <Route path="data-cleanup" element={<DataCleanup />} errorElement={<ErrorPage />} />
          <Route path="keywords" element={<Keywords />} errorElement={<ErrorPage />} />
          <Route path="users" element={<UsersPage />} errorElement={<ErrorPage />} />
        </Route>

        <Route path="user/:userId" element={<RequireAuth><UserDetailsPage /></RequireAuth>} errorElement={<ErrorPage />}>
          <Route index element={<UserDetailsTab />} errorElement={<ErrorPage />} />
          <Route path="interviews" element={<UserInterviewsTab />} errorElement={<ErrorPage />} />
          <Route path="events" element={<UserEventsTab />} errorElement={<ErrorPage />} />
        </Route>

        <Route path="*" element={<NotFoundPage />} />
      </Route>
    )
  )} />
)

export default Routes


const RequireAuth: React.FC<{ children: ReactElement }> = props => {
  const location = useLocation()
  const signInCheck = useSigninCheck()
  const isLoading = signInCheck.status == 'loading'
  const isSignedIn = signInCheck.data?.signedIn

  useEffect(() => {
    if (isLoading || isSignedIn)
      return

    const currentPath = location.pathname + location.search
    const shouldRedirectBack = !['/', '/login'].includes(location.pathname)
    const loginUrl = shouldRedirectBack ? `/login?redirectTo=${encodeURIComponent(currentPath)}` : '/login'
    window.location.replace(loginUrl)
  }, [isLoading, isSignedIn])

  return isLoading || !isSignedIn
    ? null
    : props.children
}
