import React from 'react'
import { sendPasswordResetEmail } from 'firebase/auth'
import { auth } from '../firebase'
import { Button, TextField } from '@mui/material'
import { rememberMeLoginEmail } from '../common/local-storage'
import { Link } from 'react-router-dom'

const ForgotPasswordForm: React.FC<{ onError: (err: any) => void}> = props => {
  const [isSubmitted, setIsSubmitted] = React.useState(false)

  const handleSubmit = async (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault()
    const formData = new FormData(evt.currentTarget)
    const email = formData.get('email') as string
    try {
      await sendPasswordResetEmail(auth, email)
      props.onError(null)
      setIsSubmitted(true)
    } catch (err: any) {
      props.onError(err)
      throw err
    }
  }

  if (isSubmitted) {
    return (
      <div className="text-center">
        <p className="text-slate-600">Email sent! Please check your inbox.</p>

        <div>
          <Link className="font-semibold text-indigo-600 hover:text-indigo-500" to="/login">
            Back to login page
          </Link>
        </div>
      </div>
    )
  }

  return (
    <form className="space-y-6" action="#" method="POST" onSubmit={handleSubmit}>
      <div>
        <TextField name="email" type="email" autoComplete="email" required fullWidth label="Email"
          defaultValue={rememberMeLoginEmail.get() ?? ''}
        />
      </div>

      <div>
        <Button type="submit" variant="contained" fullWidth>Submit</Button>
      </div>
    </form>
  )
}

export default ForgotPasswordForm