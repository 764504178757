import React, { ReactNode } from 'react'
import { Outlet, useParams } from 'react-router-dom'
import PageContent from '../common/components/page-content'
import { useFirestoreDocData } from '../common/hooks/firestore'
import { Customer } from '../models/customer'
import { useApplyReferralsEndpoint, useRunAdminTool } from '../common/api'
import { Updates } from '../common/types'
import { User, UserFeature } from '../models/user'
import { deleteField, doc, updateDoc } from 'firebase/firestore'
import { auth, db } from '../firebase'
import config from '../common/config'
import { get, last, startCase } from 'lodash'
import EmptyState from '../common/components/empty-state'
import { signInWithCustomToken } from 'firebase/auth'
import { trackLogin } from '../common/analytics'
import IconButton from '@mui/material/IconButton'
import MoreHoriz from '@mui/icons-material/MoreHoriz'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Tabs from '../common/components/tabs'
import Switch from '@mui/material/Switch'

const UserDetailsPage: React.FC = () => {
  const { userId } = useParams()
  const [user, isLoadingUser] = useFirestoreDocData<User | null>('users', userId)

  if (isLoadingUser)
    return null
  if (!user)
    return <EmptyState title="User does not exist" subtitle={`User with ID ${userId} does not exist`} />

  return (
    <PageContent name={`${user.firstName} ${user.lastName}`} actions={<UserActionsMenu user={user} />}>
      <div className="px-4">
        <Tabs tabs={[
          { name: 'Details', href: `/user/${userId}` },
          { name: 'Interviews', href: `/user/${userId}/interviews` },
          { name: 'Events', href: `/user/${userId}/events` }
        ]} />
      </div>

      <div className="mt-4">
        <Outlet />
      </div>
    </PageContent>
  )
}

export default UserDetailsPage

export const UserDetailsTab: React.FC = () => {
  const { userId } = useParams()
  const [user, isLoadingUser] = useFirestoreDocData<User | null>('users', userId)
  const [customer, isLoadingCustomer] = useFirestoreDocData<Customer | null>('customers', userId)

  const userFields = [
    'user.id',
    'user.email',
    'user.firstName',
    'user.lastName',
    'user.price',
    'user.referredByUserId',
    'user.isReferralCreditApplied',
    'user.referralCount',
    'user.referralCreditTotal',
    'user.isBlocked',
    'user.features',
    'customer.stripeId',
    'customer.subscription.status',
    'customer.currentPeriodUsageMinutes',
    'customer.subscription.currentPeriodStart',
    'customer.subscription.currentPeriodEnd'
  ]

  const [applyReferral] = useApplyReferralsEndpoint()

  if (isLoadingUser || isLoadingCustomer)
    return null

  if (!user)
    return <EmptyState title="User does not exist" subtitle={`User with ID ${userId} does not exist`} />

  const updateUser = async (updates: Updates<User>) => {
    const ref = doc(db, `users/${userId}`)
    await updateDoc(ref, updates)
  }

  const handlePriceChange = async (price: string) => {
    const ref = doc(db, `users/${userId}`)
    await updateDoc(ref, { price: price || null })
  }

  const handleReferredByUserIdKeyDown = async (evt: React.KeyboardEvent) => {
    if (evt.key !== 'Enter')
      return

    const value = (evt.currentTarget as HTMLInputElement).value
    if (!value) {
      await updateUser({ referredByUserId: deleteField() })
    } else if (!value.includes('@')) {
      alert('Enter the referrer\'s email address')
      return
    } else {
      const res = await applyReferral({ referredByEmail: value, referredUserIdOverride: userId })
      if (res?.data.errorMessage) {
        alert(res.data.errorMessage)
      } else {
        window.location.reload()
      }
    }
  }

  const setIsUserBlocked = async (isBlocked: boolean) => {
    if (isBlocked) {
      if (!confirm('Are you sure you want to block this user? They will be logged out immediately and wont be able to log back in.'))
        return
    }

    await updateUser({ isBlocked })
  }

  const renderers: Record<string, () => ReactNode> = {
    'user.price': () => (
      <select onChange={evt => handlePriceChange(evt.target.value)} value={user.price ?? ''} className="border rounded">
        <option value="">None</option>
        {config.prices.map(price => {
          const [perMonth, perMinuteCents] = price.split('-').map(n => parseFloat(n))
          return <option key={price} value={price}>${perMonth}/month + ${perMinuteCents / 100}/minute</option>
        })}
      </select>
    ),

    'customer.stripeId': () => (
      customer?.stripeId && <a className="font-medium text-blue-600 dark:text-blue-500 hover:underline" href={`https://dashboard.stripe.com/customers/${customer.stripeId}`}>{customer.stripeId}</a>
    ),

    'user.referredByUserId': () => (
      <input defaultValue={user.referredByUserId} autoComplete="off" className="w-full" onKeyDown={handleReferredByUserIdKeyDown} placeholder="Enter email" />
    ),

    'user.referralCreditTotal': () => user.referralCreditTotal && `$${user.referralCreditTotal}`,
    'user.isBlocked': () => <Switch value={user.isBlocked ?? false} onChange={evt => setIsUserBlocked(evt.target.checked)} />,
    'user.features': () => <UserFeaturesEditor user={user} />
  }

  return (
    <div className="flex flex-col gap-16">
      <dl className="divide-y divide-gray-200 max-w-2xl">
        <>
          {userFields.map(field => {
            const val = get({ user: user, customer }, field)
            const valStr = val?.toDate ? val.toDate().toLocaleString() : typeof val === 'boolean' ? val.toString() : val

            return (
              <div key={field} className="px-4 py-2 sm:grid sm:grid-cols-2 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">{last(field.split('.'))}</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-0">{renderers[field]?.() ?? valStr}</dd>
              </div>
            )
          })}
        </>
      </dl>
    </div>
  )
}

const UserActionsMenu: React.FC<{ user: User }> = props => {
  const [runAdminTool] = useRunAdminTool()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const isOpen = !!anchorEl
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const close = () => {
    setAnchorEl(null)
  }

  const logInAsUser = async () => {
    const res = await runAdminTool({ toolName: 'createToken', userId: props.user.id })
    const token = res?.data.token
    const { user } = await signInWithCustomToken(auth, token)
    trackLogin({ user })
    window.location.pathname = '/'
  }

  return (
    <div onClick={evt => evt.stopPropagation()}>
      <IconButton onClick={handleClick} ><MoreHoriz /></IconButton>
      <Menu
        anchorEl={anchorEl}
        open={isOpen}
        onClose={close}
      >
        <MenuItem onClick={logInAsUser}>Log in as {props.user.firstName}</MenuItem>
      </Menu>
    </div>
  )
}

const features: UserFeature[] = ['detectLanguage']

const UserFeaturesEditor: React.FC<{ user: User }> = ({ user }) => {
  const handleChange: React.ChangeEventHandler<HTMLInputElement> = async evt => {
    const feature = evt.target.name as UserFeature
    const value = evt.target.checked
    await updateDoc(doc(db, `users/${user.id}`), { [`features.${feature}`]: value })
  }

  return (
    <div>
      {features.map(f => (
        <div key={f}>
          <Switch checked={user.features?.[f] ?? false} name={f} onChange={handleChange} />
          <span className="ml-2">{startCase(f)}</span>
        </div>
      ))}
    </div>
  )
}