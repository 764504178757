import React from 'react'
import AudioFile from '@mui/icons-material/AudioFile'
import Button from '../common/components/button'
import { DateTime } from 'luxon'

type Props = {
  file: File
  onResume: () => void
  onDelete: () => void
}

const CachedRecording: React.FC<Props> = props => {
  const downloadCachedFile = async () => {
    const suggestedName = 'recording.webm'
    if (showSaveFilePicker) {
      const saveHandle = await showSaveFilePicker({ suggestedName })
      const writable = await saveHandle.createWritable()
      await writable.write(props.file)
      await writable.close()
    } else if (confirm('Are you sure you want to download this file to your computer?')) {
      const url = URL.createObjectURL(props.file)
      const link = document.createElement('a')
      link.href = url
      link.download = suggestedName
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      URL.revokeObjectURL(url)
    }
  }

  return (
    <div className="sm:rounded-lg text-left">
      <div className="px-4 py-5 sm:p-6">
        <div className="text-center">
          <h3 className="text-base font-semibold leading-6 text-gray-900">Retry upload?</h3>

          <div className="inline-block mt-1 text-sm text-gray-500 max-w-lg">Your last recording didn't finish uploading. Would you like to retry uploading it?</div>
        </div>

        <div className="rounded-md bg-white shadow px-6 py-5 sm:flex sm:items-center sm:justify-between mt-5">
          <h4 className="sr-only">Visa</h4>
          <div className="sm:flex sm:items-start text-2xl">
            <AudioFile onClick={downloadCachedFile} />

            <div className="mt-3 sm:ml-4 sm:mt-0">
              <div className="text-sm font-medium text-gray-900">Recorded audio</div>
              <div className="mt-1 text-sm text-gray-600 flex items-center">
                <div>{DateTime.fromMillis(props.file.lastModified).toLocaleString(DateTime.DATETIME_SHORT)}</div>
              </div>
            </div>
          </div>
          <div className="mt-4 sm:ml-6 sm:mt-0 sm:flex-shrink-0 flex flex-row-reverse justify-end sm:justify-start sm:flex-row gap-2">
            <Button variant="text" color="error" onClick={props.onDelete}>Delete</Button>
            <Button onClick={props.onResume}>
              Upload
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CachedRecording