import React from 'react'

const Avatar: React.FC<{ name?: string | null }> = props => {
  return props.name ? (
    <span className="inline-flex h-10 w-10 items-center justify-center rounded-full bg-gray-500">
      <span className="font-medium leading-none text-white">{getInitials(props.name)}</span>
    </span>
  ): (
    <span className="inline-block h-10 w-10 overflow-hidden rounded-full bg-gray-100">
      <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
        <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
      </svg>
    </span>
  )
}

function getInitials(name?: string | null) {
  if(!name) return ''
  const [first, last] = name.split(' ')
  return (first?.charAt(0) ?? '') + (last?.charAt(0) ?? '')
}

export default Avatar