import React, { ReactNode, useEffect } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { useRunAdminTool } from '../common/api'
import classNames from '../common/class-names'
import { UserEvent } from '../models/user-event'
import useUser from '../common/hooks/use-user'
import { DateTime } from 'luxon'
import WarningAmber from '@mui/icons-material/WarningAmber'
import Note from '@mui/icons-material/Note'
import CloudUpload from '@mui/icons-material/CloudUpload'
import AddCircleOutlined from '@mui/icons-material/AddCircleOutlined'
import Transcribe from '@mui/icons-material/Transcribe'
import Delete from '@mui/icons-material/Delete'
import Mic from '@mui/icons-material/Mic'
import ContentPaste from '@mui/icons-material/ContentPaste'
import Pause from '@mui/icons-material/Pause'
import PlayArrow from '@mui/icons-material/PlayArrow'
import Login from '@mui/icons-material/Login'

export const UserEventsTab: React.FC = () => {
  const { userId } = useParams<{ userId: string }>()
  const [searchParams] = useSearchParams()
  const [user] = useUser()
  const [runAdminTool] = useRunAdminTool()
  const [userEvents, setUserEvents] = React.useState<UserEvent[][] | null>(null)

  useEffect(() => {
    if (!userId)
      return

    const limit = searchParams.get('limit')
    runAdminTool({ toolName: 'getUserEvents', userId, limit: limit ? parseInt(limit) : undefined })
      .then(res => {
        const withDateTimes: UserEvent[] = res?.data.events.map((e: any) => ({ ...e, createdAt: DateTime.fromISO(e.createdAt) }))
        const groupedByDay = withDateTimes.reduce((agg, event) => {
          const startOfDay = event.createdAt.startOf('day').toISO()
          let lastGroup = agg[agg.length - 1]
          if (!lastGroup || lastGroup[0].createdAt.startOf('day').toISO() !== startOfDay) {
            lastGroup = [event]
            agg.push(lastGroup)
          } else {
            lastGroup.push(event)
          }
          return agg
        }, [] as UserEvent[][])
        setUserEvents(groupedByDay)
      })
  }, [userId])

  if (!user)
    return null

  return (
    <div className="flex flex-col gap-8 mx-4 sm:mx-0">
      {!userEvents && <div>Loading...</div>}
      {userEvents?.map(dayEvents => {
        const startOfDay = dayEvents[0].createdAt.startOf('day')
        return (
          <div key={startOfDay.toISO()} className="">
            <div className="py-4 text-md leading-5 text-gray-600 sticky top-16 bg-gray-50 z-10">{startOfDay.toLocaleString(DateTime.DATE_MED)}</div>

            <ul role="list" className="">
              {dayEvents.map(event => <EventRow event={event}/>)}
            </ul>
          </div>
        )
      })}
    </div>
  )
}

const duration = (seconds: number) => {
  const m = Math.floor(seconds / 60)
  const s = Math.round(seconds) % 60
  return [
    ...(m > 0 ? [`${m}m`] : []),
    ...(s > 0 ? [`${s}s`] : [])
  ].filter(Boolean).join(' ')
}

const fileSize = (bytes: number) => `${(bytes / 1e+6).toFixed(2)}MB`


const renderers: { [key: string]: (event: UserEvent) => string | { label: ReactNode, className?: string, icon?: React.FC<{ className: string }>, color?: string } } = {
  'note-section-generated': ({ data }) => ({ label: `Generated a note section with ${data.totalTokens} tokens in ${duration(data.durationSeconds)}`, icon: Note }),
  'recording_upload_started': ({ data }) => ({ label: `Started uploading ${duration(data.recordingSeconds)} ${fileSize(data.bytes)} recording`, icon: CloudUpload, className: 'text-blue-800' }),
  'recording-uploaded': ({ data }) => ({ label: `Transcribed a ${duration(data.recordingSeconds)} recording with ${data.transcriptLength} characters`, icon: Transcribe, className: 'text-blue-500' }),
  'recording_uploaded': ({ data }) => ({ label: `Back end received an uploaded ${duration(data.recordingSeconds)} recording in ${duration(data.uploadingSeconds)}`, icon: CloudUpload, className: 'text-blue-800' }),
  'file_upload': ({ data }) => ({ label: `Finished uploading a ${fileSize(data.bytes)} recording in ${duration(data.uploadingSeconds)}`, icon: CloudUpload, className: 'text-green-600' }),
  'create_interview': () => ({ label: 'Created a new interview', icon: AddCircleOutlined, className: 'text-green-600' }),
  'copy_note': () => ({ label: 'Copied a note', icon: ContentPaste, className: 'text-blue-600' }),
  'recorder_loaded': () => ({ label: 'Loaded the recorder page', icon: Mic, className: 'text-green-700' }),
  'recording_started': () => ({ label: 'Started a recording', icon: Mic, className: 'text-green-700' }),
  'recording_paused': () => ({ label: 'Paused a recording', icon: Pause, className: 'text-yellow-700' }),
  'recording_resumed': () => ({ label: 'Resumed a recording', icon: PlayArrow, className: 'text-green-700' }),
  'recording_discarded': () => ({ label: 'Discarded a recording', icon: Delete, className: 'text-orange-600' }),
  'delete_interview': () => ({ label: 'Deleted an interview', icon: Delete, className: 'text-orange-600' }),
  'login': () => ({ label: 'Logged in', icon: Login, className: 'text-green-600' }),
  'exception': e => ({ label: `${e.data.location ? 'Front end error' : 'Back end error'}: ${e.data?.description.replace(/Showing error page on "\S+":/, '')}`, icon: WarningAmber, className: 'text-red-600' }),
  default: event => event.name
}

const EventRow: React.FC<{ event: UserEvent }> = ({ event }) => {
  const [isExpanded, setIsExpanded] = React.useState(false)
  const renderer = renderers[event.name] ?? renderers.default
  let config = renderer(event)
  if (typeof config === 'string')
    config = { label: config }
  const Icon = config.icon ?? DefaultIcon
  const iconClassName = classNames('h-6 w-6', config.className)

  return (
    <li key={event.id} className="relative text-xs leading-5 text-gray-500 cursor-pointer hover:bg-gray-100 rounded py-2" onClick={e => !e.metaKey && !e.altKey && setIsExpanded(e => !e)}>
      <div className="flex gap-x-4">
        <div className={classNames('absolute left-0 top-0 flex w-6 justify-center last:h-6 -bottom-6')}>
          <div className="w-px bg-gray-200"/>
        </div>

        <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-gray-50">
          <Icon className={iconClassName} />
        </div>
        <p className="flex-auto py-0.5">
          {config.label}
        </p>
        <time dateTime={event.createdAt.toISO()!} className="flex-none py-0.5 text-xs leading-5 text-gray-500">
          {event.createdAt.toLocaleString(DateTime.TIME_WITH_SECONDS)}
        </time>
      </div>
      <div className={classNames('ml-10 h-0 overflow-hidden', isExpanded && 'h-auto')}>
        <pre className="wrap break-words overflow-auto">
          {JSON.stringify({ name: event.name, createdAt: event.createdAt, ...event.data }, null, 2)}
        </pre>
      </div>
    </li>
  )
}

const DefaultIcon = () => <div className="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300" />
