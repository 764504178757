import * as fs from 'firebase/firestore'
import { db } from '../firebase'

export default class Request {
  private static getCollection() { return fs.collection(db, 'requests') }

  public static async create(request: { type: string } & Record<string, any>) {
    await fs.addDoc(Request.getCollection(), request)
  }
}
