import React from 'react'

import './App.css'
import Routes from './routes'
import { FirebaseProvider } from './firebase'
import { ThemeProvider } from '@mui/material'
import { theme } from './mui'

const App: React.FC = () => (
  <FirebaseProvider>
    <ThemeProvider theme={theme}>
      <Routes />
    </ThemeProvider>
  </FirebaseProvider>
)


export default App

