import React, { useMemo, useRef, useState } from 'react'
import { Popover } from 'react-text-selection-popover'
import Button from '@mui/material/Button'
import SwapHoriz from '@mui/icons-material/SwapHoriz'
import TextField from '@mui/material/TextField'
import { Autocomplete, createFilterOptions, Dialog, FormControlLabel } from '@mui/material'
import medications from '../common/medication-names.json'
import useUser from '../common/hooks/use-user'
import { doc, updateDoc } from 'firebase/firestore'
import { db } from '../firebase'
import Switch from '@mui/material/Switch'

const MAX_HIGHLIGHT_LENGTH = 30
const MAX_USER_KEYWORDS = 100
const filter = createFilterOptions<string>()

export const TranscriptEditor: React.FC<{ transcript: string, onChange: (updatedTranscript: string) => void }> = props => {
  const containerRef = useRef<any>()
  const [showReplacementUi, setShowReplacementUi] = useState(false)
  const [replaceWith, setReplaceWith] = useState<string | null>('')
  const highlightedTextRef = useRef('')
  const highlightedRangeRef = useRef(null as Range | null)
  const [user] = useUser()
  const userKeywords = user?.keywords ?? []
  const options = useMemo(() => [...medications, ...userKeywords].sort(), [user?.keywords])
  const reachedKeywordLimit = userKeywords.length >= MAX_USER_KEYWORDS
  const [shouldRememberKeyword, setShouldRememberKeyword] = useState(false)

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (!replaceWith)
      return

    if (!userKeywords.includes(replaceWith) && userKeywords.length < MAX_USER_KEYWORDS && shouldRememberKeyword)
      await updateDoc(doc(db, `users/${user?.id}`), { keywords: [...userKeywords, replaceWith] })

    const replaced = props.transcript.slice(0,highlightedRangeRef.current?.startOffset)
      + replaceWith
      + props.transcript.slice(highlightedRangeRef.current?.endOffset).replace(/\n+/g, '\n')
    props.onChange(replaced)
    handleClose()
  }

  const handleClose = () => {
    setShowReplacementUi(false)
    setReplaceWith(null)
    highlightedTextRef.current = ''
    highlightedRangeRef.current = null
  }

  return (
    <>
      <div className="m-4 mx-0 flex items-baseline gap-1">
        <span className="inline-flex items-center rounded-md bg-green-100 px-2 py-1 text-xs font-medium text-green-700">TIP</span>
        <span className="text-sm text-gray-600">See a typo? Highlight it and click "Fix" to teach the AI what you actually said.</span>
      </div>
      <div className="transcript-editor relative" ref={containerRef}>
        {props.transcript}
      </div>

      <Dialog open={showReplacementUi} onClose={handleClose} maxWidth="xs" fullWidth>
        <div className="px-4 py-5 sm:p-6">
          <h3 className="text-base font-semibold leading-6 text-gray-900">Replace incorrect text "{highlightedTextRef.current}"</h3>
          <form className="flex flex-col gap-2 mt-4" onSubmit={handleSubmit}>
            <div className="flex flex-col gap-2">
              <div className="w-full">
                <Autocomplete
                  freeSolo
                  value={replaceWith}
                  onChange={(evt, value) => setReplaceWith(value)}
                  options={options}
                  renderInput={params => (
                    <TextField {...params} variant="outlined" label={'Replace with'} size="small" autoFocus fullWidth />
                  )}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params)
                    const { inputValue } = params
                    if (inputValue !== '' && !options.includes(inputValue))
                      filtered.push(inputValue)
                    return filtered
                  }}
                />
              </div>

              {!reachedKeywordLimit && (
                <FormControlLabel
                  control={<Switch onChange={evt => setShouldRememberKeyword(evt.target.checked)}/>}
                  label="Teach the AI this word for future recordings"
                  disabled={reachedKeywordLimit}
                />
              )}
              <Button type="submit" variant="contained" startIcon={<SwapHoriz />} disabled={!replaceWith}>Replace</Button>
            </div>
          </form>
        </div>

      </Dialog>

      <Popover
        target={containerRef.current}
        render={({ clientRect, isCollapsed, textContent }) => {
          const selection = window.getSelection()
          if (textContent && selection?.rangeCount) {
            highlightedTextRef.current = textContent
            highlightedRangeRef.current = selection.getRangeAt(0)
          }

          if (!clientRect || isCollapsed || !textContent || textContent.length > MAX_HIGHLIGHT_LENGTH)
            return null

          const distanceFromScreenRightEdge = document.body.clientWidth - clientRect.right
          return (
            <div
              className="absolute bg-gray-200 p-2 shadow-md text-white rounded mt-[100px] sm:mt-0"
              style={{
                top: (clientRect.top + window.scrollY - 60 - 8) + 'px',
                ...(distanceFromScreenRightEdge > 200 ? { left: `${clientRect.left}px` } : { right: `${distanceFromScreenRightEdge}px` })
              }}
            >
              <Button variant="contained" startIcon={<SwapHoriz/>} onClick={() => setShowReplacementUi(true)} className="p-1">
                Fix "{ellipsify(textContent, MAX_HIGHLIGHT_LENGTH)}"
              </Button>
            </div>
          )
        }}
      />
    </>
  )
}

const ellipsify = (text: string, maxLength: number) => {
  if (text.length <= maxLength)
    return text

  return text.slice(0, maxLength) + '...'
}