import React, { useEffect, useState } from 'react'
import FormSection from '../common/components/form-section'
import InterviewTypePicker from './interview-type-picker'
import TextField from '@mui/material/TextField'
import Switch from '@mui/material/Switch'
import Button from '@mui/material/Button'
import { Session } from '../models/session'
import useUser from '../common/hooks/use-user'
import { SubmitHandler, useForm } from 'react-hook-form'

type Props = {
  session?: Session | null
  onSubmit: (data: Partial<Session>) => void
  onCancel?: () => void
}

type FormData = Pick<Session, 'patientName' | 'patientGender' | 'patientAge' | 'patientOtherInfo'> & { useClaude: boolean }

const InterviewForm: React.FC<Props> = props => {
  const [selectedType, setSelectedType] = useState<string | null>(null)
  const { session } = props
  const [user] = useUser()
  const { register, handleSubmit, formState: { isValid, isSubmitted, errors } } = useForm({
    defaultValues: { ...session, useClaude: session?.model === 'claude' } as FormData
  })
  const errorCount = Object.keys(errors).length

  useEffect(() => {
    if (session?.type)
      setSelectedType(session?.type)
  }, [session])

  const handleValidSubmit: SubmitHandler<FormData> = async formData => {
    if (!selectedType) {
      alert('Please select a template')
      return
    }

    const { useClaude, ...sessionFormData } = formData
    const session: Partial<Session> = {
      ...props.session,
      ...sessionFormData,
      type: selectedType,
      model: formData.useClaude ? 'claude' : 'gpt3.5'
    }

    props.onSubmit(session)
  }

  if (!user) return null

  return (
    <form className="px-4 pb-[85px] sm:px-0" onSubmit={handleSubmit(handleValidSubmit)}>
      <div className="space-y-4 sm:space-y-12">
        <FormSection title="Template" subtitle="Determines the note sections that will be generated">
          <InterviewTypePicker selectedId={selectedType} onChange={setSelectedType} />
        </FormSection>

        <FormSection title="Patient information" subtitle="Optional - you can also fill this out later">
          <div className="grid grid-cols-3 gap-x-6 gap-y-8 sm:grid-cols-6">
            <div className="col-span-full sm:col-span-3">
              <TextField
                label="Name"
                fullWidth
                inputProps={register('patientName', { maxLength: { value: 50, message: 'must be <= 50 characters' } })}
                helperText={errors.patientName?.message}
                error={!!errors.patientName}
                data-private="lipsum"
              />
            </div>

            <div className="col-span-1">
              <TextField
                label="Age"
                type="number"
                fullWidth
                inputProps={register('patientAge', {
                  min: { value: 0, message: 'must be >= 0' },
                  max: { value: 150, message: 'must be <= 150' }
                })}
                helperText={errors.patientAge?.message}
                error={!!errors.patientAge}
                data-private="lipsum"
              />
            </div>

            <div className="col-span-2">
              <TextField
                label="Gender"
                fullWidth
                inputProps={register('patientGender', { maxLength: { value: 20, message: 'must be <= 20 characters' } })}
                helperText={errors.patientGender?.message}
                error={!!errors.patientGender}
                data-private="lipsum"
              />
            </div>

            <div className="col-span-full">
              <TextField
                label="Additional information"
                fullWidth
                multiline
                minRows="3"
                maxRows="15"
                inputProps={register('patientOtherInfo', { maxLength: { value: 500, message: 'must be <= 500 characters' } })}
                helperText={errors.patientOtherInfo?.message}
                error={!!errors.patientOtherInfo}
                data-private="lipsum"
              />
            </div>
          </div>
        </FormSection>

        {user.isAdmin && (
          <FormSection title="Settings">
            <div className="flex flex-col gap-y-6">
              <div className="flex items-center justify-between">
                    <span className="flex flex-grow flex-col">
                      <span className="text-sm font-medium leading-6 text-gray-900">
                        Use Claude AI
                      </span>
                      <span className="text-sm text-gray-500">
                        Use Anthropic's Claude model instead of OpenAI's GPT model to generate note sections.
                      </span>
                    </span>
                <Switch defaultChecked={session?.model === 'claude'} name="useClaude" inputProps={register('useClaude')} />
              </div>
            </div>
          </FormSection>
        )}
      </div>

      <div className="fixed bottom-0 left-0 bg-white w-full shadow-up z-10">
        <div className="mx-auto max-w-7xl p-4 sm:p-6 flex items-center justify-end gap-x-6">
          {props.onCancel && <Button onClick={props.onCancel}>Cancel</Button>}
          {!!errorCount && <span className="text-red-500">Please fix {errorCount} error{errorCount === 1 ? '' : 's'}</span>}
          <Button type="submit" variant="contained" disabled={isSubmitted && !isValid}>{session ? 'Save changes' : 'Create interview'}</Button>
        </div>
      </div>
    </form>
  )
}

export default InterviewForm