import React, { useState } from 'react'
import PageContent from '../common/components/page-content'
import { useNavigate } from 'react-router-dom'
import { Alert, Button, TextField } from '@mui/material'
import { authErrorMessages } from '../login-page/auth-error-messages'
import { Timestamp } from '@firebase/firestore'
import { auth, db } from '../firebase'
import { createUserWithEmailAndPassword } from 'firebase/auth'
import { doc, setDoc } from 'firebase/firestore'
import { trackSignup } from '../common/analytics'
import { SubmitHandler, useForm } from 'react-hook-form'
import config from '../common/config'
import { omit } from 'lodash'

const SignUpPage: React.FC = () => {
  const navigate = useNavigate()
  const [error, setError] = useState<{ code?: string, message?: string } | null>(null)

  const { register, handleSubmit, formState: { isValid, isSubmitted, errors, isSubmitting } } = useForm<FormFields>()

  const handleValidSubmit: SubmitHandler<FormFields> = async formData => {
    setError(null)

    try {
      const { user } = await createUserWithEmailAndPassword(auth, formData.email, formData.password)
      const isEarlyAdopter = new Date() < config.earlyAdopterDate
      const priceName = isEarlyAdopter ? config.earlyAdopterPriceName : config.defaultPriceName
      const userData = omit(formData, ['password', 'confirmPassword'])
      await setDoc(doc(db, `users/${user.uid}`), {
        ...userData,
        email: userData.email?.toLowerCase(),
        price: priceName,
        createdAt: Timestamp.now()
      })
      trackSignup({ user })
      navigate('/account')
    } catch (err: any) {
      setError(err)
    }
  }

  return (
    <PageContent>
      <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <div className="mx-auto h-10 w-auto text-center text-4xl font-black bg-clip-text text-transparent"
            style={{ backgroundImage: 'linear-gradient(0deg, #5663c8, #00aeef)' }}>
            NoteZap
          </div>
          <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            Create your account
          </h2>
        </div>


        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
          <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
            {error && (
              <Alert severity="error" className="mb-8">
                {(error.code && authErrorMessages[error.code]) ?? error.message ?? 'Login failed'}
              </Alert>
            )}

            <form className="space-y-4" action="#" method="POST" onSubmit={handleSubmit(handleValidSubmit)}>
              <div className="flex space-x-2">
                <TextField
                  label="First name"
                  helperText={errors.firstName?.message}
                  error={!!errors.firstName}
                  inputProps={register('firstName', {
                    required: true,
                    maxLength: { value: 100, message: 'must be <100 characters' }
                  })}
                />
                <TextField
                  label="Last name"
                  helperText={errors.lastName?.message}
                  error={!!errors.lastName}
                  inputProps={register('lastName', {
                    required: true,
                    maxLength: { value: 100, message: 'must be <100 characters' }
                  })}
                />
              </div>
              <TextField
                type="email"
                autoComplete="email"
                fullWidth
                label="Email"
                helperText={errors.email?.message}
                error={!!errors.email}
                inputProps={register('email', {
                  required: true,
                  pattern: { value: /^\S+@\S+\.\S+$/, message: 'must be a valid email' },
                  maxLength: { value: 100, message: 'must be <200 characters' }
                })}
              />
              <TextField
                type="password"
                autoComplete="new-password"
                fullWidth
                label="Password"
                helperText={errors.password?.message}
                FormHelperTextProps={{ className: 'whitespace-pre' }}
                error={!!errors.password}
                inputProps={register('password', {
                  required: true,
                  minLength: { value: 8, message: 'Must be at least 8 characters' },
                  pattern: { value: /(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])/, message: 'Must include:\n- 1 lower case letter\n- 1 upper case letter\n- 1 number' }
                })}
              />

              <TextField
                type="password"
                autoComplete="new-password"
                fullWidth
                label="Confirm password"
                helperText={errors.confirmPassword?.message}
                error={!!errors.confirmPassword}
                inputProps={register('confirmPassword', {
                  required: true,
                  validate: (value, allValues) => value === allValues.password || 'Must match password',
                  deps: ['password']
                })}
              />

              <Button type="submit" variant="contained" fullWidth disabled={(isSubmitted && !isValid) || isSubmitting}>Create account</Button>
            </form>
          </div>
        </div>
      </div>
    </PageContent>
  )
}

type FormFields = {
  firstName: string
  lastName: string
  email: string
  password: string
  confirmPassword: string
}

export default SignUpPage