import React from 'react'
import MuiButton from '@mui/material/Button'
import { PropsOf } from '@emotion/react'
import { useNavigate } from 'react-router-dom'
import CircularProgress from '@mui/material/CircularProgress'

type Props = PropsOf<typeof MuiButton> & {
  isLoading?: boolean
  verifyClickMessage?: string
}

const Button: React.FC<Props> = props => {
  const { href, isLoading, verifyClickMessage, ...muiProps } = props
  const navigate = useNavigate()

  const handleClick = (evt: any) => {
    if (verifyClickMessage && !confirm(verifyClickMessage))
      return

    props.onClick?.(evt)
    if (href)
      navigate(href)
  }

  return (
    <MuiButton
      role={href ? 'link' : undefined}
      data-href={href}
      variant="contained"
      {...muiProps}
      onClick={handleClick}
      {...(isLoading ? { disabled: true, startIcon: <CircularProgress size={20}/> } : { startIcon: props.startIcon }) }
    />
  )
}

export default Button