import { Disclosure, Menu, Transition } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/solid'
import classNames from '../class-names'
import React, { Fragment, useEffect } from 'react'
import Button from './button'
import { Link, matchPath, NavLink, useLocation } from 'react-router-dom'
import Avatar from './avatar'
import useUser from '../hooks/use-user'
import Add from '@mui/icons-material/Add'
import useAutoLogout from '../hooks/use-auto-logout'
import { useAuth } from 'reactfire'

const Navbar: React.FC = () => {
  const location = useLocation()
  const auth = useAuth()
  const [user] = useUser()

  useAutoLogout()

  const signOut = () => {
    void auth.signOut()
    window.location.reload()
  }

  useEffect(() => {
    if (user.isBlocked)
      signOut()
  }, [user?.isBlocked, auth])

  const navLinks = [
    { name: 'Interviews', href: '/' },
    user.isAdmin && { name: 'Admin', href: '/admin' }
  ].filter(Boolean) as { name: string, href: string }[]

  const userNavigation: { name: string, onClick?: () => void, href?: string}[] = [
    { name: 'Account', href: '/account' },
    { name: 'Help', href: 'mailto:support@notezap.com' },
    { name: 'Sign out', onClick: signOut }
  ]

  const hideNavBar = !!matchPath(location.pathname, '/login')
  if (hideNavBar)
    return null

  return (
    <Disclosure as="nav" className="bg-gray-800  fixed top-0 min-w-full z-50">
      {({ open }) => (
        <>
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="flex h-16 justify-between">
              <div className="flex items-center">
                <div className="-ml-2 mr-2 flex items-center md:hidden">
                  {/* Mobile menu button */}
                  <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                    <span className="sr-only">Open main menu</span>
                    {open ? (
                      <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </Disclosure.Button>
                </div>
                <div className="flex flex-shrink-0 items-center mx-auto h-10 w-auto text-center text-xl font-black bg-clip-text text-transparent" style={{ backgroundImage: 'linear-gradient(0deg, #5663c8, #00aeef)' }}>
                  <Link to="/">NoteZap</Link>
                </div>
                <div className="hidden md:ml-6 md:flex md:items-center md:space-x-4">
                  {navLinks.map((item) => {
                    return (
                      <NavLink
                        key={item.name}
                        to={item.href}
                        className={({ isActive }) => classNames(
                          isActive ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                          'rounded-md px-3 py-2 text-sm font-medium'
                        )}
                      >
                        {item.name}
                      </NavLink>
                    )
                  })}
                </div>
              </div>
              <div className="flex items-center gap-3">
                <div className="flex-shrink-0">
                  <Button startIcon={<Add />} href="/interview/new">
                    New Interview
                  </Button>
                </div>

                <div className="hidden md:flex md:flex-shrink-0 md:items-center">
                  {/* Profile dropdown */}
                  <Menu as="div" className="relative">
                    <div>
                      <Menu.Button className="flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                        <span className="sr-only">Open user menu</span>
                        <Avatar name={user?.firstName} />
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        {userNavigation.map((item) => (
                          <Menu.Item key={item.name}>
                            {({ active }) => item.href ? (
                              <NavLink
                                to={item.href}
                                className={classNames(
                                  active ? 'bg-gray-100' : '',
                                  'block px-4 py-2 text-sm text-gray-700 w-full text-left'
                                )}
                              >
                                {item.name}
                              </NavLink>
                            ) : (
                              <Menu.Button key={item.name} onClick={item.onClick} className={classNames(
                                active ? 'bg-gray-100' : '',
                                'block px-4 py-2 text-sm text-gray-700 w-full text-left'
                              )}>
                                {item.name}
                              </Menu.Button>
                            )}
                          </Menu.Item>
                        ))}
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
            </div>
          </div>

          {/* Mobile popout nav menu */}
          <Disclosure.Panel className="md:hidden">
            <div className="space-y-1 px-2 pb-3 pt-2 sm:px-3">
              {navLinks.map((item) => {
                const isActive = matchPath(item.href, location.pathname)
                return (
                  <Disclosure.Button
                    key={item.name}
                    as={NavLink}
                    to={item.href}
                    className={classNames(
                      isActive ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                      'block rounded-md px-3 py-2 text-base font-medium'
                    )}
                    aria-current={isActive ? 'page' : undefined}
                  >
                    {item.name}
                  </Disclosure.Button>
                )
              })}
            </div>

            <div className="border-t border-gray-700 pb-3 pt-4">
              <div className="flex items-center px-5 sm:px-6">
                <Avatar name={user?.firstName} />
                <div className="ml-3">
                  <div className="text-base font-medium text-white">{user?.firstName}</div>
                  <div className="text-sm font-medium text-gray-400">{user?.email}</div>
                </div>
              </div>
              <div className="mt-3 space-y-1 px-2 sm:px-3">
                {userNavigation.map((item) => {
                  return item.href ? (
                    <Disclosure.Button
                      key={item.name}
                      as="a"
                      href={item.href}
                      className="block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white"
                    >
                      {item.name}
                    </Disclosure.Button>
                  ) : (
                    <Disclosure.Button
                      key={item.name}
                      onClick={item.onClick}
                      className="block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white w-full text-left"
                    >
                      {item.name}
                    </Disclosure.Button>
                  )
                })}
              </div>
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}

export default Navbar