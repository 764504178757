import { useFirestoreDoc } from 'reactfire'
import { doc, getFirestore } from 'firebase/firestore'
import { useMemo } from 'react'

const useDoc = <TDoc>(path: string): [TDoc | null, boolean, Error | null] => {
  const document = useFirestoreDoc(doc(getFirestore(), path))

  const isLoading = document?.status === 'loading'
  const snapshot = document?.data?.data()
  const data = useMemo(() => snapshot && {
    ...snapshot,
    id: document.data.id
  }, [snapshot])

  return [data as TDoc, isLoading, document.error ?? null]
}

export default useDoc