import React, { useEffect   } from 'react'
import { Outlet } from 'react-router-dom'
import Navbar from './common/components/navbar'
import { useUser } from 'reactfire'

const PageLayout: React.FC = () => {
  const { data: user } = useUser()

  useEffect(function updateViewHeight() {
    const onResize = () => {
      const viewHeight = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', viewHeight.toString())
    }
    window.addEventListener('resize', onResize)
    return () => window.removeEventListener('resize', onResize)
  }, [])

  return (
    <div className="min-h-full">
      {!!user && <Navbar />}
      <div className="pt-16">
        <Outlet />
      </div>
    </div>
  )
}

export default PageLayout
