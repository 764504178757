import React, { ChangeEventHandler, useCallback, useEffect, useRef, useState } from 'react'
import { Session, SessionStatus } from '../models/session'
import { LiveAudioVisualizer } from 'react-audio-visualize'
import { useReactMediaRecorder } from './react-media-recorder-3'
import Mic from '@mui/icons-material/Mic'
import IconButton from '@mui/material/IconButton'
import Save from '@mui/icons-material/Save'
import Pause from '@mui/icons-material/Pause'
import PlayArrow from '@mui/icons-material/PlayArrow'
import MicOff from '@mui/icons-material/MicOff'
import Delete from '@mui/icons-material/Delete'
import CircularProgress from '@mui/material/CircularProgress'
import { Input } from '@mui/material'
import Button from '@mui/material/Button'
import UploadFile from '@mui/icons-material/UploadFile'
import { useWakeLock } from 'react-screen-wake-lock'
import logger from '../common/logger'
import EmptyState from '../common/components/empty-state'
import {
  trackCachedRecordingDelete, trackCachedRecordingUpload, trackFileUpload, trackRecorderLoaded, trackRecordingDiscarded,
  trackRecordingPaused, trackRecordingResumed, trackRecordingStarted, trackRecordingUploaded,
  trackRecordingUploadStarted
} from '../common/analytics'
import { ExclamationTriangleIcon } from '@heroicons/react/20/solid'
import useIsAudioDetected from './use-is-audio-detected'
import * as recordingCache from '../common/recording-cache'
import CachedRecording from './cached-recording'
import { unstable_usePrompt } from 'react-router-dom'

const ACCEPTED_FILE_EXTENSIONS = [
  '.mp3',
  '.wav',
  '.m4a',
  '.webm'
]
const navAwayConfirmMessage = 'Are you sure you want to leave? Your recording will be lost.'

const Recorder: React.FC<{ session: Session }> = props => {
  const [uploadPercent, setUploadPercent] = useState<number | null>(null)
  const fileInputRef = React.useRef<HTMLInputElement>(null)
  const wakeLock = useWakeLock()
  const [hasUploadError, setHasUploadError] = useState(false)
  const [recordingSeconds, setRecordingSeconds] = useState(0)
  const shouldSaveRef = useRef(false)
  const [cachedRecording, setCachedRecording] = useState<File | null>(null)
  const [recordingTime, setRecordingTime] = useState(0)
  const [timerInterval, setTimerInterval] = useState<any | null>(null)
  const [recordingBlob, setRecordingBlob] = useState<Blob | null>(null)
  const [shouldConfirmNavAway, setShouldConfirmNavAway] = useState(false)
  const [isStartingRecording, setIsStartingRecording] = useState(false)

  useEffect(() => {
    trackRecorderLoaded()
    recordingCache.getFileIfExists(props.session.id)
      .then(async handle => {
        if (!handle) return

        const file = await handle.getFile()
        if (file.size > 0)
          setCachedRecording(file)
      })
    }, [])

  unstable_usePrompt({ when: shouldConfirmNavAway, message: navAwayConfirmMessage })
  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (shouldConfirmNavAway) {
        event.returnValue = navAwayConfirmMessage
        return event.returnValue
      }
    }
    addEventListener('beforeunload', handleBeforeUnload, { capture: true })
    return () => removeEventListener('beforeunload', handleBeforeUnload, { capture: true })

  }, [shouldConfirmNavAway])

  useEffect(() => {
    if (props.session.status !== SessionStatus.Uploading && uploadPercent !== null)
      setUploadPercent(null)
  }, [props.session.status, uploadPercent])

  const handleFileSelect: ChangeEventHandler<HTMLInputElement> = async evt => {
    const file = evt.target.files?.[0]
    if (!file)
      return

    void uploadRecording(file, true)
  }

  const startTimer = useCallback(() => {
    const interval = setInterval(() => {
      setRecordingTime(currentTime => currentTime + 1)
    }, 1000)
    setTimerInterval(interval)
  }, [setRecordingTime])

  const stopTimer = useCallback(() => {
    if (timerInterval)
      clearInterval(timerInterval)
    setTimerInterval(undefined)
  }, [timerInterval, setTimerInterval])

  const uploadRecording = async (blob: Blob, isFileUpload = false) => {
    const { session } = props
    const uploadingStart = new Date()
    const bytes = blob.size
    if (!bytes) {
      logger.error('Unable to upload recording. Blob is empty')
      setHasUploadError(true)
      return
    }
    if (uploadPercent !== null) {
      logger.info('uploadRecording called while another upload is in progress')
      return
    }


    setHasUploadError(false)
    setUploadPercent(0)
    trackRecordingUploadStarted({ templateId: session.type, sessionId: session.id, recordingSeconds, bytes })

    try {
      await session.update({ status: SessionStatus.Uploading })

      const uploadTask = session.uploadRecording(blob)
      uploadTask.on('state_changed',
        snapshot => {
          const percent = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
          setUploadPercent(currentPercent => {
            if (currentPercent !== percent)
              logger.info(`Upload state: ${snapshot.state}. Percent: ${percent}%`)
            return percent
          })
        },
        err => {
          setHasUploadError(true)
          setShouldConfirmNavAway(false)
          logger.error(`Received error "${err.code}" from uploadRecording uploadTask. Server response: "${err.customData?.serverResponse}"`, err)
        },
        () => {
          void deleteCachedUpload()
          setShouldConfirmNavAway(false)
          const uploadingSeconds = (new Date().getTime() - uploadingStart.getTime()) / 1000
          const templateId = session.type
          if (isFileUpload) {
            trackFileUpload({ uploadingSeconds, templateId, bytes })
          } else {
            trackRecordingUploaded({ recordingSeconds, uploadingSeconds, templateId, sessionId: session.id, bytes })
          }
        }
      )
    } catch (err) {
      setHasUploadError(true)
      setUploadPercent(null)
      logger.error('Error uploading recording', err)
    }
  }

  const handleRecorderStop = (_url: string, blob: Blob) => {
    if (shouldSaveRef.current) {
      setRecordingBlob(blob)
      void uploadRecording(blob)
    } else {
      deleteCachedUpload().catch(err => logger.error('Error deleting cached upload', err))
    }
  }

  const {
    startRecording,
    stopRecording,
    pauseRecording,
    resumeRecording,
    clearBlobUrl,
    status: recorderStatus,
    previewAudioStream,
    error: recorderError
  } =
  useReactMediaRecorder({
    video: false,
    audio: {
      noiseSuppression: true,
      echoCancellation: false,
      channelCount: 1
    },
    cacheKey: props.session.id,
    onStop: handleRecorderStop
  })

  const isRecording = recorderStatus === 'recording'
  const isPaused = recorderStatus === 'paused'
  const isRecordingStarted = isRecording || isPaused
  const arePermissionsDenied = recorderError === 'permission_denied'
  const [isAudioDetected, isAudioDetectorReady] = useIsAudioDetected(previewAudioStream)

  useEffect(() => {
    if (recordingTime !== 0)
      setRecordingSeconds(recordingTime)
  }, [recordingTime])

  const setPreventSleep = (preventSleep: boolean) => {
    if (!wakeLock.isSupported) return

    const promise = preventSleep ? wakeLock.request() : wakeLock.release()
    promise.catch(err => logger.error('Error with wake lock:', err))
  }

  const handleStartRecordingClick = async () => {
    if (isRecording || isStartingRecording)
      return
    setIsStartingRecording(true)

    try {
      await startRecording()

      startTimer()
      setPreventSleep(true)
      setShouldConfirmNavAway(true)
      trackRecordingStarted({ sessionId: props.session.id })
    } catch (err) {
      logger.error('Error starting recording', err)
    } finally {
      setPreventSleep(false)
      setShouldConfirmNavAway(false)
      setIsStartingRecording(false)
    }
  }

  const handleDiscardClick = () => {
    shouldSaveRef.current = false
    setShouldConfirmNavAway(false)
    stopRecording()
    stopTimer()
    setPreventSleep(false)
    trackRecordingDiscarded()
    clearBlobUrl()
    setRecordingBlob(null)
    setRecordingTime(0)
    setShouldConfirmNavAway(false)
    void deleteCachedUpload(false)
  }

  const handleSaveClick = () => {
    shouldSaveRef.current = true
    stopRecording()
    stopTimer()
  }

  const handlePauseResumeClick = () => {
    if (isPaused) {
      trackRecordingResumed()
      resumeRecording()
      startTimer()
    } else {
      trackRecordingPaused()
      pauseRecording()
      stopTimer()
    }
  }

  const resumeCachedUpload = async () => {
    trackCachedRecordingUpload({ bytes: cachedRecording?.size ?? 0 })
    if (cachedRecording) {
      setRecordingBlob(cachedRecording)
      uploadRecording(cachedRecording)
        .catch(err => logger.error('Error uploading cached recording', err))
    }
  }

  const deleteCachedUpload = async (track?: boolean) => {
    if (track)
      trackCachedRecordingDelete()
    void recordingCache.deleteFile(props.session.id)
    setCachedRecording(null)
  }

  if (recordingBlob && hasUploadError) {
    return (
      <EmptyState
        title="Upload error"
        subtitle="Something went wrong uploading the recording. Please try again."
        actions={[
          <Button variant="outlined" startIcon={<UploadFile />} onClick={() => uploadRecording(recordingBlob)} key="1">Try again</Button>
        ]}
      />
    )
  }

  if (cachedRecording && uploadPercent === null)
    return <CachedRecording file={cachedRecording} onResume={resumeCachedUpload} onDelete={() => deleteCachedUpload(true)} />

  if (arePermissionsDenied) {
    return (
      <EmptyState
        icon={<MicOff fontSize="large" />}
        title="Missing microphone permissions"
        subtitle="You must allow microphone access to record audio. Depending on your browser you should be able to fix this via a permissions menu in the browser's address bar. Look for a microphone or lock icon. Make sure to refresh the page after granting permissions."
      />
    )
  }

  return (
    <div className="flex flex-col items-center gap-4">
      <div
        className="flex items-center bg-gray-200 !rounded-full p-2 transition-all duration-200"
        style={{ width: isRecordingStarted ? 376 : 64, height: 64 }}
      >
        {uploadPercent !== null && (
          <div className="min-w-[48px] h-[48px] flex items-center justify-center">
            <CircularProgress size={42} value={uploadPercent} variant="determinate" />
            <span className="absolute text-xs">{uploadPercent}%</span>
          </div>
        )}

        {uploadPercent === null && (
          <IconButton
            size="large"
            onClick={() => isRecordingStarted ? handleSaveClick() : handleStartRecordingClick()}
            title={isRecordingStarted ? 'Save recording' : 'Start recording'}
            className="!text-blue-700"
          >
            {isRecordingStarted ? <Save/> : <Mic/>}
          </IconButton>
        )}

        <div className={`flex items-center transition-opacity duration-200 grow ${isRecordingStarted ? 'opacity-100 delay-200' : 'opacity-0'}`}>
          <span className={'text-center w-[44px]'}>
            {Math.floor(recordingTime / 60)}:
            {String(recordingTime % 60).padStart(2, '0')}
          </span>

          <Visualizer status={recorderStatus} stream={previewAudioStream} />

          {isRecordingStarted && (
            <IconButton
              size="large"
              onClick={handlePauseResumeClick}
              title={isPaused ? 'Resume recording' : 'Pause recording'}
            >{isPaused ? <PlayArrow /> : <Pause />}</IconButton>
          )}

          {isRecordingStarted && (
            <IconButton
              size="large"
              onClick={() => { if (confirm('Are you sure you want to discard this recording? All audio will be lost.')) handleDiscardClick()}}
              title="Discard Recording"
            ><Delete /></IconButton>
          )}
        </div>
      </div>

      {isRecordingStarted && !isAudioDetected && isAudioDetectorReady && (
        <div className="border-l-4 border-yellow-400 bg-yellow-50 p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <ExclamationTriangleIcon className="h-5 w-5 text-yellow-400" aria-hidden="true" />
            </div>
            <div className="ml-3">
              <p className="text-sm text-yellow-700">No audio detected. Check your microphone settings.</p>
            </div>
          </div>
        </div>
      )}

      {!isRecordingStarted && uploadPercent === null && (
        <>
          <div>or</div>
          <div>
            <Button startIcon={<UploadFile />} variant="outlined" onClick={() => fileInputRef.current?.click()}>Upload</Button>
            <Input type="file" onChange={handleFileSelect} inputProps={{ accept: ACCEPTED_FILE_EXTENSIONS.join(','), ref: fileInputRef }} className="!hidden"/>
          </div>
        </>
      )}
    </div>
  )
}

export default Recorder

const Visualizer: React.FC<{ stream: MediaStream | null, status: string }> = props => {
  const mediaRecorder = useRef<Pick<MediaRecorder, 'stream' | 'state'> | null>(null)

  useEffect(() => {
    if (props.stream) {
      const updates = { stream: props.stream, state: props.status as RecordingState }
      if (mediaRecorder.current) {
        Object.assign(mediaRecorder.current, updates)
      } else {
        mediaRecorder.current = updates
      }
    }
  }, [props.stream, props.status])

  return (
    <div className="px-4 grow">
      {mediaRecorder.current?.stream && (
        <LiveAudioVisualizer
          mediaRecorder={mediaRecorder.current as MediaRecorder}
          barWidth={2}
          gap={2}
          width={140}
          height={30}
          fftSize={512}
          maxDecibels={-10}
          minDecibels={-80}
          smoothingTimeConstant={0.4}
        />
      )}
    </div>
  )
}
