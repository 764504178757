import React, { useEffect, useState } from 'react'
import classNames from '../common/class-names'
import { ReleaseStatusBadge } from '../new-interview-page/new-interview-page'
import SessionType from '../models/session-type'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import MoreVert from '@mui/icons-material/MoreVert'
import ListItemText from '@mui/material/ListItemText'
import Delete from '@mui/icons-material/Delete'
import ContentCopy from '@mui/icons-material/ContentCopy'
import { useAuth } from 'reactfire'
import { where } from 'firebase/firestore'
import uniqBy from 'lodash/uniqBy'
import Edit from '@mui/icons-material/Edit'
import EditInterviewTypeDialog from './edit-interview-type-dialog'
import { lastUsedInterviewType } from '../common/local-storage'
import useUser from '../common/hooks/use-user'

type Props = {
  selectedId: string | null
  onChange: (id: string) => void
}

const InterviewTypePicker: React.FC<Props> = props => {
  const auth = useAuth()
  const mySessionTypes = SessionType.useQuery(where('createdBy', '==', auth.currentUser!.uid))
  const publicSessionTypes = SessionType.useQuery(where('isPublic', '==', true))

  const sessionTypes = uniqBy([...mySessionTypes ?? [], ...publicSessionTypes ?? []], 'id')

  useEffect(() => {
    if (sessionTypes?.length && (!props.selectedId || !sessionTypes.find(type => type.id === props.selectedId))) {
      const lastUsed = sessionTypes.find(type => type.id === lastUsedInterviewType.get())
      props.onChange(lastUsed?.id ?? sessionTypes[0].id)
    }
  }, [props.selectedId, sessionTypes])

  const handleSessionTypeSelected = (sessionType: SessionType) => {
    props.onChange(sessionType.id)
    lastUsedInterviewType.set(sessionType.id)
  }

  const handleSessionTypeDeleted = (id: string) => {
    if (props.selectedId === id)
      props.onChange(sessionTypes[0].id)
  }

  if (!sessionTypes) return null

  const selectedType = sessionTypes.find(type => type.id === props.selectedId ?? sessionTypes[0].id)

  return (
    <div>
      <ul role="radiogroup" className="grid grid-cols-1 sm:grid-cols-2 gap-2 lg:gap-4 lg:grid-cols-3">
        {sessionTypes.map((sessionType) => {
          const isSelected = props.selectedId === sessionType.id
          return (
            <li key={sessionType.id}
              role="radio"
              aria-checked={isSelected}
              className={classNames(
                isSelected ? 'border-blue-600' : 'border-slate-00',
                'col-span-1 flex shadow-sm h-24 block bg-white hover:bg-slate-100 border-2 cursor-pointer rounded-md  overflow-hidden'
              )}
              onClick={() => handleSessionTypeSelected(sessionType)}
            >
              <div
                style={{ backgroundImage: `url(${sessionType.thumbnailUrl})` }}
                className="flex w-24 flex-shrink-0 items-center justify-center text-sm font-medium text-white bg-cover bg-gray-100"/>
              <div
                className="flex flex-1 items-center justify-between truncate">
                <div className="flex-1 overflow-hidden whitespace-normal px-4 py-2 text-sm font-medium">
                  <span>{sessionType.name.replace(/ \((alpha|beta)\)$/, '')}</span>
                  <div>
                    <ReleaseStatusBadge name={sessionType.name} />
                  </div>
                </div>

                <div className="flex-shrink-0 pr-2">
                  <ActionMenu sessionType={sessionType} onNewSessionTypeCreated={props.onChange} onDeleted={handleSessionTypeDeleted} />
                </div>
              </div>
            </li>
          )
        })}
      </ul>

      <div>
        <h2 className="text-base font-semibold leading-7 text-gray-900 mt-4">Generated note sections</h2>
        <ul className="text-sm leading-6 text-gray-600 list-disc ml-6">
          {selectedType?.noteSections?.map(section => (
            <li key={section.name} className="list-item">{section.name}</li>
          ))}
        </ul>
      </div>
    </div>
  )
}

const ActionMenu: React.FC<{ sessionType: SessionType, onNewSessionTypeCreated: (id: string) => void, onDeleted: (id: string) => void }> = props => {
  const [user] = useUser()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false)
  const [editedSessionType, setEditedSessionType] = useState(props.sessionType)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleCustomizeClicked = async () => {
    handleClose()
    setEditedSessionType({
      ...props.sessionType,
      id: null as any,
      name: `Customized ${props.sessionType.name}`,
      noteSections: props.sessionType.noteSections.map(s => ({ ...s })),
      isPublic: false
    })
    setIsEditDialogOpen(true)
  }
  const handleDelete = async () => {
    handleClose()
    const confirmed = confirm('Are you sure you want to delete this interview?')
    if (confirmed) {
      await SessionType.delete(props.sessionType.id)
      props.onDeleted(props.sessionType.id)
    }
  }

  const handleEditClicked = () => {
    handleClose()
    setEditedSessionType(props.sessionType)
    setIsEditDialogOpen(true)
  }

  const handleEditDialogClose = (newSessionTypeId?: string) => {
    setIsEditDialogOpen(false)
    if (newSessionTypeId)
      props.onNewSessionTypeCreated(newSessionTypeId)
  }

  const canEdit = user.isAdmin || props.sessionType.createdBy === user.id

  return (
    <div onClick={evt => evt.stopPropagation()}>
      <IconButton
        id="actions-menu-button"
        aria-controls={open ? 'actions-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <MoreVert />
      </IconButton>
      <Menu
        id="actions-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{ 'aria-labelledby': 'actions-menu-button' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      >
        {canEdit && (
          <MenuItem onClick={handleEditClicked}>
            <ListItemIcon><Edit /></ListItemIcon>
            <ListItemText>Edit</ListItemText>
          </MenuItem>
        )}
        {canEdit && (
          <MenuItem onClick={handleDelete}>
            <ListItemIcon><Delete className="text-red-500" /></ListItemIcon>
            <ListItemText className="text-red-500">Delete</ListItemText>
          </MenuItem>
        )}
        <MenuItem onClick={handleCustomizeClicked} role="link">
          <ListItemIcon><ContentCopy /></ListItemIcon>
          <ListItemText>Customize</ListItemText>
        </MenuItem>
      </Menu>

      {isEditDialogOpen && (
        <EditInterviewTypeDialog sessionType={editedSessionType} onClose={handleEditDialogClose} />
      )}
    </div>
  )
}


export default InterviewTypePicker