import React, { useState } from 'react'
import {
  Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, TextareaAutosize, Tooltip
} from '@mui/material'
import TextField from '@mui/material/TextField'
import IconButton from '@mui/material/IconButton'
import Delete from '@mui/icons-material/Delete'
import Button from '@mui/material/Button'
import Add from '@mui/icons-material/Add'
import SessionType from '../models/session-type'
import { pick } from 'lodash'
import Switch from '@mui/material/Switch'
import useUser from '../common/hooks/use-user'
import { trackCreateInterviewTemplate, trackEditInterviewTemplate } from '../common/analytics'

type Props = {
  sessionType: SessionType
  onClose: (newSessionTypeId?: string) => void
}

const EditInterviewTypeDialog: React.FC<Props> = props => {
  const [sessionType, setSessionType] = useState(props.sessionType)
  const [user] = useUser()

  const handleDeleteNoteSection = (index: number) => {
    if (sessionType.noteSections[index].name.trim() !== '' && !confirm('Are you sure you want to delete this note section?'))
      return

    setSessionType(st => ({ ...st, noteSections: st.noteSections.filter((_, i) => i !== index) }))
  }

  const handleAddNoteSection = () => {
    setSessionType(st => ({ ...st, noteSections: [...st.noteSections, { name: '', prompt: '' }] }))
  }

  const handleNoteSectionChange = (index: number, change: Partial<{ name: string, prompt: string}>) => {
    setSessionType(st => ({ ...st, noteSections: st.noteSections.map((section, i) => i === index ? { ...section, ...change } : section) }))
  }

  const handleSaveClicked = async () => {
    const sectionCount = sessionType.noteSections.length ?? 0
    if (sessionType.id) {
      await SessionType.update(sessionType.id, pick(sessionType, ['name', 'noteSections', 'isPublic']))
      trackCreateInterviewTemplate({ sectionCount })
      props.onClose()
    } else {
      const newSession = await SessionType.create(sessionType)
      trackEditInterviewTemplate({ sectionCount })
      props.onClose(newSession.id)
    }
  }

  return (
    <Dialog maxWidth="md" fullWidth fullScreen={window.innerWidth < 640} open classes={{ paper: '!m-0 sm:!m-4' }}>
      <DialogTitle>{sessionType.id ? 'Edit Template' : 'Create New Template'}</DialogTitle>
      <DialogContent className="flex flex-col gap-4">
        <div className="pt-4 flex gap-4">
          <TextField label="Template name" defaultValue={sessionType.name} size="small" onChange={evt => setSessionType(st => ({ ...st, name: evt.target.value }))} className="bg-white w-full sm:w-1/2"/>
          {user.isAdmin && (
            <FormControlLabel
              className="whitespace-nowrap !mr-0"
              control={
                <Switch checked={sessionType.isPublic} onChange={evt => setSessionType(st => ({ ...st, isPublic: evt.target.checked }))}/>
              }
              label="Is public" />
          )}
        </div>

        {sessionType.noteSections.map((noteSection, idx) => (
          <div className="flex flex-col gap-4 relative rounded-md bg-gray-50 border p-6 shadow" key={idx}>
            <div className="flex gap-2">
              <TextField
                size="small"
                label="Section name"
                fullWidth
                defaultValue={noteSection.name}
                onChange={evt => handleNoteSectionChange(idx, { name: evt.target.value })}
                inputProps={{ maxLength: 150 }}
                className="bg-white"/>
              <Tooltip title="Delete section">
                <IconButton onClick={() => handleDeleteNoteSection(idx)}>
                  <Delete />
                </IconButton>
              </Tooltip>
            </div>
            <div className="relative">
              <label className="inline-block bg-white absolute" style={{ color: 'rgba(0, 0, 0, 0.6)', fontSize: '1rem', transform: 'scale(0.75)', top: -12, left: 4 }} id={`section-${idx}-prompt-label`}>
                AI prompt
              </label>
              <TextareaAutosize
                maxLength={700}
                minRows="3"
                maxRows="6"
                defaultValue={noteSection.prompt}
                onChange={evt => handleNoteSectionChange(idx, { prompt: evt.target.value })}
                className="w-full border border-gray-300 rounded-md px-4 py-2 max-h-60 min-h-[50px]"
                aria-labelledby={`section-${idx}-prompt-label`}
              />
            </div>
          </div>
        ))}

        <Button fullWidth startIcon={<Add />} onClick={handleAddNoteSection} disabled={sessionType.noteSections.length >= 10}>Add note section</Button>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => props.onClose()}>Cancel</Button>
        <Button variant="contained" onClick={handleSaveClicked}>{sessionType.id ? 'Update template' : 'Create new template'}</Button>
      </DialogActions>
    </Dialog>
  )
}

export default EditInterviewTypeDialog