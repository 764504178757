import React, { ReactNode } from 'react'
import CircularProgress from '@mui/material/CircularProgress'

type Props = {
  title: string
  subtitle: ReactNode,
  icon?: ReactNode
  progress?: true | number
  actions?: ReactNode[]
}

const EmptyState: React.FC<(Props)> = props => (
  <div className="text-center px-4">
    {props.progress === true && (
      <div className="mx-auto h-12 w-12">
        <CircularProgress />
      </div>
    )}
    {props.icon && (
      <div className="mx-auto h-12 w-12">
        {props.icon}
      </div>
    )}
    <h3 className="mt-2 text-m font-semibold text-gray-900">{props.title}</h3>
    <div className="inline-block mt-1 text-sm text-gray-500 max-w-lg">{props.subtitle}</div>

    {props.actions && (
      <div className="mt-6 flex gap-2 justify-center">
        {props.actions}
      </div>
    )}
  </div>
)

export default EmptyState