import React, { useState } from 'react'
import { Session, SessionStatus } from '../models/session'
import { useNavigate } from 'react-router-dom'
import useUser from '../common/hooks/use-user'
import { useHttpsCallable } from 'react-firebase-hooks/functions'
import { fbFunctions } from '../firebase'
import Request from '../models/request'
import { trackDeleteInterview, trackRegenerateNotes } from '../common/analytics'
import IconButton from '@mui/material/IconButton'
import MoreHoriz from '@mui/icons-material/MoreHoriz'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import Edit from '@mui/icons-material/Edit'
import ListItemText from '@mui/material/ListItemText'
import Refresh from '@mui/icons-material/Refresh'
import Cloud from '@mui/icons-material/Cloud'
import Delete from '@mui/icons-material/Delete'
import { openSessionRecordingsInFirebaseStorage } from './interview-details-page'

export const InterviewActionMenu: React.FC<{ session: Session }> = props => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const navigate = useNavigate()
  const [user] = useUser()
  const [reTranscribeSession, isReTranscribingSession] = useHttpsCallable(fbFunctions, 'retranscribesession')
  const [isDeleting, setIsDeleting] = useState(false)
  const { status } = props.session
  const hasRecordings = !!props.session.recordings?.length
  const hasTranscript = !!props.session.transcript
  const canRegenerateNote = hasTranscript && ![SessionStatus.New, SessionStatus.TranscriptTooShort, SessionStatus.MissingSessionType].includes(status)
  const canReTranscribe = hasRecordings && (
    (status === SessionStatus.Error && !hasTranscript)
    || user.isAdmin
  )
  const canEdit = status !== SessionStatus.TranscriptTooShort

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleRegenerate = async () => {
    handleClose()
    await Request.create({ type: 'summarize-session', sessionId: props.session.id })
    trackRegenerateNotes()
  }
  const handleReTranscribe = async () => {
    handleClose()
    await reTranscribeSession({ sessionId: props.session.id })
  }
  const handleDelete = async () => {
    handleClose()
    const confirmed = confirm('Are you sure you want to delete this interview?')
    if (!confirmed)
      return

    setIsDeleting(true)
    await Session.delete(props.session.id)
    trackDeleteInterview()
    navigate('/')
  }

  const handleEditClicked = () => {
    handleClose()
    navigate('/interview/' + props.session.id + '/edit')
  }

  if (isDeleting)
    return null

  return (
    <div onClick={evt => evt.stopPropagation()}>
      <IconButton
        id="actions-menu-button"
        aria-controls={open ? 'actions-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        <MoreHoriz/>
      </IconButton>
      <Menu
        id="actions-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{ 'aria-labelledby': 'actions-menu-button' }}
      >
        {canEdit && (
          <MenuItem onClick={handleEditClicked} role="link">
            <ListItemIcon><Edit/></ListItemIcon>
            <ListItemText>Edit</ListItemText>
          </MenuItem>
        )}
        {canRegenerateNote && (
          <MenuItem onClick={handleRegenerate}>
            <ListItemIcon><Refresh/></ListItemIcon>
            <ListItemText>Regenerate notes</ListItemText>
          </MenuItem>
        )}
        {canReTranscribe && (
          <MenuItem onClick={handleReTranscribe} disabled={isReTranscribingSession}>
            <ListItemIcon><Refresh/></ListItemIcon>
            <ListItemText>Re-transcribe</ListItemText>
          </MenuItem>
        )}
        {user.isAdmin && !!props.session.recordings?.length && (
          <MenuItem onClick={() => openSessionRecordingsInFirebaseStorage(props.session.recordings?.[0])}>
            <ListItemIcon><Cloud/></ListItemIcon>
            <ListItemText>View recordings</ListItemText>
          </MenuItem>
        )}
        <MenuItem onClick={handleDelete}>
          <ListItemIcon><Delete className="text-red-500"/></ListItemIcon>
          <ListItemText className="text-red-500">Delete interview</ListItemText>
        </MenuItem>
      </Menu>
    </div>
  )
}