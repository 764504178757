import { useUser as libUseUser } from 'reactfire'
import useDoc from './use-doc'
import { User } from '../../models/user'
import { useMemo } from 'react'

const useUser = (): [User, boolean, Error | null] => {
  const { data: authUser } = libUseUser()
  const [userDoc, isLoading, error] = useDoc<User>(authUser ? `users/${authUser?.uid}` : '')
  const user = useMemo(() => !!userDoc && {
    id: authUser?.uid,
    email: authUser?.email,
    name: authUser?.displayName,
    ...userDoc as any
  }, [userDoc])

  return [user, isLoading, error]
}

export default useUser