import React from 'react'
import PageContent from '../common/components/page-content'
import { updateDoc, doc } from 'firebase/firestore'
import { db } from '../firebase'
import { useNavigate, useParams } from 'react-router-dom'
import { Session } from '../models/session'
import Request from '../models/request'
import InterviewForm from './interview-form'
import ErrorPage from '../common/components/error-page'
import { trackEditInterview } from '../common/analytics'

const EditInterviewPage: React.FC = () => {
  const navigate = useNavigate()
  const { sessionId } = useParams()
  const [session, isLoadingSession] = Session.useGet(sessionId)

  const handleSubmit = async (sessionData: Partial<Session>) => {
    if (!session)
      return

    trackEditInterview({
      templateId: session.type,
      changedTemplate: session.type !== sessionData.type,
      changedPatientName: session.patientName !== sessionData.patientName,
      changedPatientAge: session.patientAge !== sessionData.patientAge,
      changedPatientGender: session.patientGender !== sessionData.patientGender,
      changedPatientOtherInfo: session.patientOtherInfo !== sessionData.patientOtherInfo
    })

    await updateDoc(doc(db, `sessions/${session.id}`), sessionData)

    if (session?.recordings?.length)
      await Request.create({ type: 'summarize-session', sessionId: session.id })

    navigate(`/interview/${session.id}`)
  }

  if (isLoadingSession)
    return null
  if (!session)
    return <ErrorPage title="Interview not found">This interview may have been deleted or you may not have access to it.</ErrorPage>

  return (
    <PageContent
      name={session.name}
      subheader={
        <div>
          {session.createdAt.toLocaleString()}
          {session.patientName && ` - ${session.patientName}`}
        </div>
      }
    >
      <InterviewForm session={session} onSubmit={handleSubmit} onCancel={() => navigate(-1)}/>
    </PageContent>
  )
}

export default EditInterviewPage