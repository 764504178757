import React from 'react'
import PageContent from '../common/components/page-content'
import { db } from '../firebase'
import * as fs from 'firebase/firestore'
import { Tooltip } from '@mui/material'
import Stepper from '../common/components/stepper'
import { useNavigate } from 'react-router-dom'
import { Session } from '../models/session'
import useUser from '../common/hooks/use-user'
import useCustomer from '../common/hooks/use-customer'
import InterviewForm from '../edit-interview-page/interview-form'
import { trackCreateInterview } from '../common/analytics'

const NewInterviewPage: React.FC = () => {
  const [user] = useUser()
  const [customer, isLoadingCustomer] = useCustomer()
  const navigate = useNavigate()

  const handleSubmit = async (data: Partial<Session>) => {
    const session = {
      ...data,
      createdBy: user.id,
      createdAt: fs.serverTimestamp(),
      updatedAt: fs.serverTimestamp()
    }
    const created = await fs.addDoc(fs.collection(db, 'sessions'), session)
    trackCreateInterview({ templateId: session.type!, hasPatientName: !!session.patientName, hasPatientAge: !!session.patientAge, hasPatientGender: !!session.patientGender, hasPatientOtherInfo: !!session.patientOtherInfo })
    navigate(`/interview/${created.id}`)
  }

  if (!user || isLoadingCustomer) return null

  const subscriptionStatus = customer?.subscription?.status
  const hasActiveSubscription = ['active', 'trialing'].includes(subscriptionStatus ?? '')
  if (!hasActiveSubscription && user.price) {
    navigate('/account', { replace: true })
    return null
  }

  return (
    <PageContent name="New interview">
      <Stepper currentStepIdx={0} />

      <InterviewForm onSubmit={handleSubmit} />
    </PageContent>
  )
}

export const ReleaseStatusBadge: React.FC<{ name: string }> = props => {
  const status = props.name.match(/\((alpha|beta)\)$/i)?.[1]
  if (!status)
    return null

  const badge = {
    alpha: { textColor: 'text-blue-700', bgColor: 'bg-blue-100', tooltip: 'This template is fairly new.' },
    beta: { textColor: 'text-indigo-700', bgColor: 'bg-indigo-100', tooltip: 'We\'re still testing this template out but it generally works well.' }
  }[status.toLowerCase()]

  if (!badge)
    return null

  return (
    <Tooltip title={badge.tooltip} arrow placement="right" enterDelay={800}>
      <span className={`inline-flex items-center rounded-md px-1.5 py-0.5 text-xs font-medium capitalize ${badge.textColor} ${badge.bgColor}`}>
        {status}
      </span>
    </Tooltip>
  )
}

export default NewInterviewPage