import * as fs from 'firebase/firestore'
import { auth, db } from '../firebase'
import * as reactfire from 'reactfire'
import { QueryConstraint, updateDoc } from 'firebase/firestore'
import { Timestamp } from '@firebase/firestore'

export default class SessionType {
  public readonly id: string
  public readonly name: string
  public readonly thumbnailUrl: string | null
  public readonly thumbnailBlurHash: string | null
  public readonly noteSections: { name: string, prompt: string }[]
  public readonly isPublic: boolean
  public readonly createdAt: Date
  public readonly createdBy: string

  constructor(overrides: SessionType) {
    this.id = overrides.id
    this.name = overrides.name
    this.thumbnailUrl = overrides.thumbnailUrl
    this.thumbnailBlurHash = overrides.thumbnailBlurHash
    this.noteSections = overrides.noteSections
    this.isPublic = overrides.isPublic
    this.createdAt = overrides.createdAt
    this.createdBy = overrides.createdBy
  }

  private static getCollection() {
    return fs.collection(db, 'session_types') as fs.CollectionReference<SessionType>
  }

  public static useGetAll(): SessionType[] | null {
    const result = reactfire.useFirestoreCollectionData(SessionType.getCollection())
    return result.data?.map(fromFirebase)
  }

  public static useQuery(...constraints: QueryConstraint[]): SessionType[] | null {
    const query = fs.query(SessionType.getCollection(), ...constraints)
    const result = reactfire.useFirestoreCollectionData(query)

    return result.data?.map(fromFirebase)
  }

  public static async update(id: string, updates: Partial<SessionType>): Promise<void> {
    const ref = fs.doc(SessionType.getCollection(), id)
    await updateDoc(ref, updates)
  }

  public static async delete(id: string) {
    return fs.deleteDoc(fs.doc(SessionType.getCollection(), id))
  }

  static async create(overrides: Partial<SessionType>): Promise<SessionType> {
    const sessionType = {
      name: 'New session',
      noteSections: [],
      isPublic: false,
      thumbnailUrl: null,
      thumbnailBlurHash: null,
      ...overrides,
      createdBy: auth.currentUser!.uid,
      createdAt: Timestamp.now() as any
    }

    const res = await fs.addDoc(SessionType.getCollection(), sessionType)
    return new SessionType({ ...sessionType, id: res.id })
  }
}

const fromFirebase = (doc: any): SessionType => doc && new SessionType({
  ...doc,
  id: doc.id ?? doc.NO_ID_FIELD,
  createdAt: doc.createdAt?.toDate()
})