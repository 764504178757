import { openDB } from 'idb'
import { DateTime } from 'luxon'

const StoreName = 'recordings'
const DeleteRecordingsAfterHours = 24 * 7

const getRecordingCacheDb = () => openDB('NoteZapRecordingCache', 1, {
  upgrade(db) {
    db.createObjectStore(StoreName, { keyPath: 'id' })
  }
})

async function deleteOldRecords() {
  const db = await getRecordingCacheDb()
  const records = (await db.getAll(StoreName)).map(r => ({ ...r, date: DateTime.fromISO(r.date) }))
  const now = DateTime.now()
  const oldRecords = records.filter(r => {
    return now.diff(r.date, 'hours').hours > DeleteRecordingsAfterHours
  })

  if (oldRecords.length) {
    console.log(`Deleting ${oldRecords.length} cached recordings`)
    await Promise.all(oldRecords.map(r => db.delete(StoreName, r.id)))
  }
}

async function deleteOldFiles() {
  const cacheRootDirectory = await window.navigator.storage.getDirectory()
  const oldMillis = DateTime.now().minus({ hours: DeleteRecordingsAfterHours }).toMillis()
  for await (const handle of cacheRootDirectory.values()) {
    if (handle.kind === 'file' && (await handle.getFile()).lastModified < oldMillis) {
      console.log('deleting old file', handle.name)
      await deleteFile(handle.name)
    }
  }
}

void deleteOldRecords()
void deleteOldFiles()

export async function getFileHandle(id: string): Promise<FileSystemFileHandle> {
  const cacheRootDirectory = await window.navigator.storage.getDirectory()
  return cacheRootDirectory.getFileHandle(id, { create: true })
}

export async function getFileIfExists(id: string): Promise<FileSystemFileHandle | null> {
  const cacheRootDirectory = await window.navigator.storage.getDirectory()
  return cacheRootDirectory.getFileHandle(id)
    .catch(() => null)
}

export async function deleteFile(id: string) {
  const cacheRootDirectory = await window.navigator.storage.getDirectory()
  await cacheRootDirectory.removeEntry(id).catch(() => null)
}

export type CachedRecordingRecord = {
  id: string
  date: DateTime
  seconds: number
  arrayBuffer: ArrayBuffer
}